import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SoftwareMaintenance = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div className="container py-3">
                <div className="row">

                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/SoftwareMaintenance/maintance.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40" data-aos="fade-up" >Software Maintenance</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down">
                        At TechX, we understand that the journey of software development doesn’t end with deployment. Our comprehensive Software Maintenance services ensure that your applications continue to function optimally, adapt to changing business needs, and remain secure over time. We focus on proactive maintenance strategies to minimize downtime, improve performance, and extend the lifespan of your software investments.
                        </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-3">
                <div className="row mb-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-right" >Regular Updates & Enhancements</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down">
                        Keeping Your Software Current We provide ongoing updates and enhancements to keep your software aligned with the latest industry standards and technological advancements. Whether it’s adding new features, improving existing functionalities, or ensuring compatibility with updated platforms, our team works diligently to keep your software relevant and efficient.
                        </p>
                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/SoftwareMaintenance/Updates.jpg" className="w-100" style={{borderRadius:15}} />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/SoftwareMaintenance/Updates.jpg" className="w-100" style={{borderRadius:15}} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-right" >Regular Updates & Enhancements</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down" >
                        Keeping Your Software Current We provide ongoing updates and enhancements to keep your software aligned with the latest industry standards and technological advancements. Whether it’s adding new features, improving existing functionalities, or ensuring compatibility with updated platforms, our team works diligently to keep your software relevant and efficient.
                        </p>
                    </div>
                </div>


                <div className="row mb-2 pt-lg-4 pt-2">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/SoftwareMaintenance/optimize.jpg" className="w-100" style={{borderRadius:15}} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className="color-about f_25 mb-1" data-aos="fade-left">Performance Optimization</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">Maximizing Efficiency Performance issues can significantly impact user satisfaction and productivity. Our Performance Optimization services focus on identifying and resolving bottlenecks, optimizing resource utilization, and enhancing the overall speed and responsiveness of your software. We continuously monitor your applications to ensure they run at peak performance.

                        </p>
                    </div>
                </div>

                <div className="row mb-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-left">Bug Fixes & Troubleshooting</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">
                        Swift Resolution of Issues Bugs and errors can disrupt operations and cause frustration among users. At TechX, we prioritize quick and effective resolution of any issues that arise. Our dedicated support team is always ready to troubleshoot problems, apply patches, and ensure your software runs smoothly without interruptions.
                        </p>
                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/SoftwareMaintenance/BugFixes.png" className="w-100" />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/SoftwareMaintenance/BugFixes.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about mb-1  f_25" data-aos="fade-left">Bug Fixes & Troubleshooting</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">
                        Swift Resolution of Issues Bugs and errors can disrupt operations and cause frustration among users. At TechX, we prioritize quick and effective resolution of any issues that arise. Our dedicated support team is always ready to troubleshoot problems, apply patches, and ensure your software runs smoothly without interruptions.
                        </p>
                    </div>
                </div>

                <div className="row mb-2 ">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/SoftwareMaintenance/ssl.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="mb-1 color-about f_25" data-aos="fade-right">Security & Compliance Management</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-right">Protecting Your Data and Systems In today’s digital landscape, security is paramount. We implement robust security measures to protect your software from threats like data breaches and cyberattacks. Our team also ensures that your software remains compliant with relevant regulations and industry standards, providing peace of mind that your data and systems are secure.</p>
                    </div>
                </div>
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default SoftwareMaintenance