import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Pages/Website/styles/DropDown.css";

const ServicesDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [linksOpen, setLinksOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState("");
  useEffect(() => {
    if (previousLocation !== location.pathname) {
      setIsOpen(false);
    }
    setPreviousLocation(location.pathname);
  }, [location, previousLocation]);

  const handleCloseLinks = () => {
    setLinksOpen(false);
    props.dataAlert(linksOpen);
  };

  return (
    <div
      className={`link has-dropdown-menu ${isOpen ? "open-dropdown-menu" : ""}`}
    >
      <a href="#" onClick={toggleDropdown}>
        Services
      </a>
      {/* ------------------------------------- */}
      <div className={`dropdown_height dropdown-menu list-unstyled ${isOpen ? "open" : ""}`} >
        <div className="text-end d-xl-flex d-none justify-content-end" style={{ position: "absolute", width: '100%' }}><button className="textColor" onClick={() => { setIsOpen(false) }} style={{ fontSize: 35, background: "transparent", border: 0, padding: '0px 15px' }}>&times;</button></div>

        {/* coloumn 1 */}
        <ul className="design">
          <h1>Core IT and Managed</h1>
          <li>
            <Link
              to="/ManagedIT"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/technical-support.png"
                alt="AI-icon"
              />
              Managed IT
            </Link>
          </li>

          <li>
            <Link to="/VoIP" className="dm-link" onClick={handleCloseLinks}>
              <img
                src="../../assets/images/icons/NavbarIcons/system.png"
                alt="AI-icon"
              />
              VoIP System
            </Link>
          </li>

          <li>
            <Link to="/MDM" className="dm-link" onClick={handleCloseLinks}>
              <img
                src="../../assets/images/icons/NavbarIcons/application.png"
                alt="AI-icon"
              />
              MDM
            </Link>
          </li>
          <li>
            <Link
              to="/RemoteSupport"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/teleworking.png"
                alt="AI-icon"
              />
              Remote Support
            </Link>
          </li>

          <li>
            <Link
              to="/ITCompliance"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/compliant.png"
                alt="AI-icon"
              />
              IT Compliance
            </Link>
          </li>
          <li>
            <Link
              to="/ITOutsourcing"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/thin.png"
                alt="AI-icon"
              />
              IT Outsourcing
            </Link>
          </li>
          <li>
            <Link to="/ItTraining" className="dm-link" onClick={handleCloseLinks}>
              <img
                src="../../assets/images/icons/NavbarIcons/technical-support.png"
                alt="AI-icon"
              />
              IT Training
            </Link>
          </li>

          
        </ul>
        <ul className="design">
          <h1>Advanced Technology</h1>
          <li>
            <Link
              to="/ArtificialIntelligence"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/artifical-intelligence.png"
                alt="AI-icon"
              />
              Artificial Intelligence
            </Link>
          </li>

          <li>
            <Link
              to="/cyber-security"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/cyber-security.png"
                alt="AI-icon"
              />
              Cyber Security
            </Link>
          </li>
          <li>
            <Link
              to="/CloudService"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/cloud-hosting.png"
                alt="AI-icon"
              />
              CloudX Service
            </Link>
          </li>
          <li>
            <Link
              to="/EmergingTechIntegration"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/emergency-call.png"
                alt="AI-icon"
              />
              Emerging Tech Integration
            </Link>
          </li>
          {/* <li>
            <Link
              to="/"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/businessautomation.png"
                alt="AI-icon"
              />
              Data & Business Integration
            </Link>
          </li> */}
          <li>
            <Link
              to="/BIanalytics"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/business.png"
                alt="AI-icon"
              />
              BI & Analytics
            </Link>
          </li>
          <li>
            <Link
              to="/DataAnalytics"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/cloud-service.png"
                alt="AI-icon"
              />
              Data Analytics
            </Link>
          </li>
          <li>
            <Link
              to="/ServerManagement"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/training.png"
                alt="AI-icon"
              />
              Server Management
            </Link>
          </li>
        </ul>



        {/* Coloumn 2 */}
        <ul className="design">
          <h1>Hardware Services</h1>
          <li>
            <Link
              to="/HardwareProcurement"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/Hardware.png"
                alt="AI-icon"
              />
              Hardware Procurement
            </Link>
          </li>
            <li>
            <Link to="/HardwareInstallConfig" className="dm-link" onClick={handleCloseLinks}>
              <img
                src="../../assets/images/icons/NavbarIcons/computer.png"
                alt="AI-icon"
              />
              Hardware Install & Config
            </Link>
          </li>
          <li>
            <Link
              to="/HardwareMaintenance"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/hardwaremain.png"
                alt="AI-icon"
              />
              Hardware Maintenance
            </Link>
          </li>
          <li>
            <Link
              to="/ITInfrastructure"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/information-technology.png"
                alt="AI-icon"
              />
              IT Infrastructure
            </Link>
          </li>
          <li>
            <Link
              to="/HardwareService"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/hardwareservice.png"
                alt="AI-icon"
              />
              Hardware as a Service (HaaS)
            </Link>
          </li>
          <li>
            <Link
              to="/EnterpriseHardware"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/hardwareautomation.png"
                alt="AI-icon"
              />
              Enterprise Hardware
            </Link>
          </li>
          <li>
            <Link
              to="/NetworkComm"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/networkremote.png"
                alt="AI-icon"
              />
              Network & Comm Hardware
            </Link>
          </li>
          <li>
            <Link
              to="/SecurityHardware"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/securityhardware.png"
                alt="AI-icon"
              />
              Security Hardware
            </Link>
          </li>

        </ul>

        {/* Coloumn 3 */}
        <ul className="design">
          <h1>Consulting & Strategic</h1>
          <li>
            <Link to="/ConsultandManagement" className="dm-link" onClick={handleCloseLinks}>
              <img
                src="../../assets/images/icons/NavbarIcons/consultancytalk.png"
                alt="AI-icon"
              />
              Consultancy & Management
            </Link>
          </li>
          <li>
            <Link
              to="/ITConsultancy"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/itconsultancy.png"
                alt="AI-icon"
              />
              IT Consultancy
            </Link>
          </li>
          <li>
            <Link
              to="/ITStrategy"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/itstrategy.png"
                alt="AI-icon"
              />
              IT Strategy
            </Link>
          </li>
          <li>
            <Link
              to="/TechAssessments"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/techassessments.png"
                alt="AI-icon"
              />
              Tech Assessments
            </Link>
          </li>
          <li>
            <Link
              to="/DigitalTransformation"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/digitaltransformation.png"
                alt="AI-icon"
              />
              Digital Transformation
            </Link>
          </li>
          <li>
            <Link
              to="/BusinessAutomation"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/businessautomation.png"
                alt="AI-icon"
              />
              Business Automation
            </Link>
          </li>
          <li>
            <Link
              to="/ItGovernance"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/itgovernance.png"
                alt="AI-icon"
              />
              IT Governance & Risk
            </Link>
          </li>
          <li>
            <Link
              to="/Projectmanagement"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/project.png"
                alt="AI-icon"
              />
              Project Management
            </Link>
          </li>

        </ul>





        <ul className="design">
          <h1>Software and Integration</h1>
          <li>
            <Link
              to="/CustomSoftware"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/customsoftwaredevelopment.png"
                alt="AI-icon"
              />
              Custom Software Development
            </Link>
          </li>
          <li>
            <Link
              to="/MobileAppDevelopment"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/mobileappdevelopment.png"
                alt="AI-icon"
              />
              Web & Mobile App Development
            </Link>
          </li>
          <li>
            <Link
              to="/APIDevelop"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/apidevelop.png"
                alt="AI-icon"
              />
              API Develop & Integration
            </Link>
          </li>
          <li>
            <Link
              to="/EnterpriseSolutions"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/enterprisesolutions.png"
                alt="AI-icon"
              />
              Enterprise Solutions
            </Link>
          </li>
          <li>
            <Link
              to="/DevOpsCICD"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/devops.png"
                alt="AI-icon"
              />
              DevOps & CI/CD
            </Link>
          </li>
          <li>
            <Link
              to="/SoftwareMaintenance"
                className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/softwaremaintenance.png"
                alt="AI-icon"
              />
              Software Maintenance
            </Link>
          </li>
          <li>
            <Link
              to="/NetworkManagement"
              className="dm-link"
              onClick={handleCloseLinks}
            >
              <img
                src="../../assets/images/icons/NavbarIcons/network.png"
                alt="AI-icon"
              />
              Network Management
            </Link>
          </li>

        </ul>
      </div>

    </div>
  );
};

export default ServicesDropdown;
