import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BusinessAutomation = ()=>{
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return(
        <>
                    <div className="container py-3">
                <div className="row">

                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/BusinessAutomation/businessauto.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center  ">
                        <h3 className="ml-lg-1 color-about F_40 text-lg-start text-center" data-aos="fade-up" >Business Automation</h3>
                        <p className="ml-lg-1 textColor f_18 text-justify" data-aos="fade-down">
                        At TechX, we empower businesses to streamline their operations and enhance efficiency through cutting-edge Business Automation solutions. By automating repetitive tasks, optimizing workflows, and integrating advanced technologies, we help you reduce manual effort, minimize errors, and accelerate your business processes. Our approach to automation is designed to improve productivity, lower operational costs, and give your team the freedom to focus on more strategic, value-driven activities.
                        </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-3 mt-3">
                <div className="row mb-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1 mr-lg-1" data-aos="fade-right" >Workflow Automation</h3>
                        <p className="textColor f_18 text-justify mr-lg-1" data-aos="fade-down">
                        Our Workflow Automation service focuses on automating routine processes that are time-consuming and prone to human error. By leveraging advanced automation tools, we streamline your operations, ensuring tasks are completed faster and with greater accuracy. From automated approval processes to seamless data transfers between systems, our solutions are tailored to fit your unique business needs, allowing you to maintain consistency and efficiency across all departments.
                        </p>
                    </div>
                    <div className="d-flex col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/BusinessAutomation/workflow.jpg" className="w-100" style={{borderRadius:15}} />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/BusinessAutomation/workflow.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-right" >Workflow Automation</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down" >
                        Our Workflow Automation service focuses on automating routine processes that are time-consuming and prone to human error. By leveraging advanced automation tools, we streamline your operations, ensuring tasks are completed faster and with greater accuracy. From automated approval processes to seamless data transfers between systems, our solutions are tailored to fit your unique business needs, allowing you to maintain consistency and efficiency across all departments.
                        </p>
                    </div>
                </div>


                <div className="row mb-2 pt-lg-2 pt-1">
                    <div className="d-flex col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/BusinessAutomation/rpa.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className="color-about f_25 mb-1 ml-lg-1" data-aos="fade-left">Robotic Process Automation (RPA)                        </h3>
                        <p className="textColor f_18 text-justify ml-lg-1" data-aos="fade-left">Robotic Process Automation (RPA) takes automation to the next level by deploying software robots that mimic human actions within your existing IT systems. RPA can handle a wide range of tasks, from data entry and processing to complex decision-making processes. Our RPA solutions are designed to integrate seamlessly with your current systems, enabling you to automate even the most intricate workflows. This not only reduces the workload on your employees but also enhances accuracy and compliance across your operations.

                        </p>
                    </div>
                </div>

                <div className="row mb-2 pt-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="mr-lg-1 color-about f_25 mb-1" data-aos="fade-left">Data Integration and Management</h3>
                        <p className="mr-lg-1 textColor f_18 text-justify" data-aos="fade-left">
                        Effective business automation requires seamless integration and management of data across various systems and platforms. Our Data Integration and Management solutions ensure that your business information flows smoothly between applications, databases, and external systems. By automating data exchange and synchronization, we eliminate data silos and ensure that your teams have access to real-time, accurate information. This integrated approach helps you make informed decisions faster and improves overall business agility.
                        </p>
                    </div>
                    <div className="d-flex col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/BusinessAutomation/Integration.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                </div>
                <div className="row mb-2 pt-1  d-lg-none d-block">
                    <div className="d-flex col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/BusinessAutomation/Integration.jpg" className="w-100" style={{borderRadius:15}} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about mb-1  f_25" data-aos="fade-left">Data Integration and Management</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">
                        Effective business automation requires seamless integration and management of data across various systems and platforms. Our Data Integration and Management solutions ensure that your business information flows smoothly between applications, databases, and external systems. By automating data exchange and synchronization, we eliminate data silos and ensure that your teams have access to real-time, accurate information. This integrated approach helps you make informed decisions faster and improves overall business agility.
                        </p>
                    </div>
                </div>

                <div className="row mb-2 ">
                    <div className="d-flex col-lg-6 p-lg-0 pt-lg-2 pt-1 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/BusinessAutomation/Supply.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="ml-lg-1 mb-1 color-about f_25" data-aos="fade-right">Supply Chain Automation</h3>
                        <p className="ml-lg-1 textColor f_18 text-justify" data-aos="fade-right">In today’s competitive market, supply chain efficiency is crucial. Our Supply Chain Automation solutions are designed to optimize and automate every aspect of your supply chain, from procurement and inventory management to order fulfillment and logistics. By automating these processes, we help you reduce lead times, minimize stockouts, and enhance collaboration with suppliers and partners. This results in a more responsive and resilient supply chain that can adapt quickly to changing market conditions.</p>
                    </div>
                </div>
                <div className="se-head mt-sm-3 mt-3 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link  to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default BusinessAutomation