
import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const SecurityHardware = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, []);
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(/assets/images/pages/SecurityHardware/securitybanner2.jpg)`,
                    minHeight: '100%',
                    backgroundSize: 'cover'
                }}
            >
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-6 col-md-4 d-md-block d-none"></div>
                        <div className="col-lg-6  col-md-8 d-md-block d-none">
                            <h3 className=" text-center AI-text-title-one white-theme-text-AI color-about " data-aos="fade-up"
                            >
                                Comprehensive Security Hardware
                            </h3>
                        </div>
                        <div className="col-12 d-md-none d-flex justify-content-center align-items-center">
                            <h3 className="text-center AI-text-title-one white-theme-text-AI color-about" data-aos="fade-up" style={{ paddingTop: 15, paddingBottom: 15, background: "#0202028f", borderRadius: 15 }}
                            >
                                Comprehensive Security Hardware
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <p className="f_22 textColor text-justify" data-aos="fade-down">At  <span className="heading-text-AI-banner" style={{ fontSize: 22 }}>TechX</span>, we understand that security is a top priority for businesses of all sizes. Our Security Hardware solutions are designed to provide robust, reliable protection for your assets, data, and employees. From advanced surveillance systems to access control and perimeter security, our offerings are tailored to meet your specific needs, ensuring peace of mind and safeguarding your operations. </p>
            </div>
            <hr style={{ color: '#e2e9ee' }} />
            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Surveillance Systems</h3>
                <p className="f_22 textColor text-center mb-sm-2 mb-1">Our cutting-edge surveillance systems provide comprehensive monitoring to keep your premises secure.</p>
                <div className="row text-center">
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-sm-3 mb-2 " data-aos="fade-down">
                        <div className="box color-3 mr-lg-1">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/SecurityHardware/camera.jpg"}
                                className="h_img_security img-fluid"
                                alt="Image"
                                style={{ width: '100%', borderRadius: 15 }}
                            />
                            <h3 className="box-title f_22">High-Definition Cameras</h3>
                            <p className="f_18 box-para text-justify textColor" data-aos="fade-down">Capture crystal-clear, detailed footage with HD and 4K resolution, ensuring that every critical moment is recorded with the highest clarity. Our cameras offer wide-angle coverage, allowing you to monitor expansive areas with precision. Built to withstand harsh weather conditions, the weatherproof design ensures reliable performance in both indoor and outdoor environments. Whether you need to secure a small office or a large industrial site, these cameras deliver unmatched quality and durability.</p>                       </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-sm-3 mb-2 " data-aos="fade-down">
                        <div className="box color-5 ml-lg-1">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/SecurityHardware/moniter.jpg"}
                                className="h_img_security img-fluid"
                                alt="Image"
                                style={{ width: '100%', borderRadius: 15 }}
                            />
                            <h4 className="box-title f_22">Remote Monitoring</h4>
                            <p className="f_18 box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Stay connected to your security system no matter where you are with our secure cloud-based platform. Access live feeds and recordings remotely, ensuring that you have eyes on your premises at all times. Our user-friendly smartphone app allows you to monitor your security system on the go, providing convenience and peace of mind. Real-time notifications alert you immediately to any suspicious activity, enabling you to take swift action and maintain control over your property’s safety.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-sm-3 mb-2 " data-aos="fade-down">
                        <div className="box color-2 mr-lg-1">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/SecurityHardware/advanced.jpg"}
                                className="h_img_security img-fluid"
                                alt="Image"
                                style={{ width: '100%', borderRadius: 15 }}
                            />
                            <h4 className="box-title f_22">Advanced Analytics</h4>
                            <p className="f_18 box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Enhance your surveillance capabilities with AI-driven analytics that automatically detect and alert you to unusual movements. The facial recognition feature allows for accurate identification and tracking of individuals, adding an extra layer of security to your premises. Behavioral analysis tools provide deep insights into patterns and anomalies, helping you make informed decisions and respond proactively to potential threats. These advanced features work together to create a smarter, more responsive security system. </p>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 mb-sm-3 mb-2 " data-aos="fade-down">
                        <div className="box color-2 ml-lg-1">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/SecurityHardware/remote.jpg"}
                                className="h_img_security img-fluid"
                                alt="Image"
                                style={{ width: '100%', borderRadius: 15 }}
                            />
                            <h4 className="box-title f_22"> Night Vision</h4>
                            <p className="f_18 box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                Ensure round-the-clock protection with our infrared-capable cameras that deliver clear, detailed images even in complete darkness. Low-light performance is optimized to maintain visibility in dim conditions, so you never miss a thing, day or night. With automatic switching between day and night modes, your surveillance system adapts seamlessly to changing light conditions, providing consistent security coverage at all times. Whether indoors or outdoors, these cameras are designed to perform in any lighting situation.
                            </p>
                        </div>
                    </div>

                    
                </div>
            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Upgrade Service</h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Technology evolves rapidly, and so should your hardware. TechX offers comprehensive upgrade services to ensure your infrastructure keeps pace with the latest advancements, providing you with enhanced performance, security, and capabilities.
                </p>
                <div className="row nm-style4 mt-sm-2 mt-1">
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Hardware Upgrades</h3>
                        <p data-aos="fade-rown">
                            We assist with the replacement and upgrade of outdated hardware components, including processors, memory, storage devices, and networking equipment. Our team ensures that your systems are up-to-date with the latest technology, providing enhanced performance and reliability.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">System Reconfiguration</h3>
                        <p data-aos="fade-rown">
                            Our experts reconfigure your systems to accommodate new hardware, ensuring compatibility and optimal performance. We fine-tune system settings, BIOS configurations, and network parameters to seamlessly integrate new components into your existing infrastructure.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Data Migration</h3>
                        <p data-aos="fade-rown">
                            Seamless data migration services ensure that all your important information is safely transferred to new systems without any loss or downtime. We handle everything from transferring files to setting up new data structures, ensuring a smooth transition.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Software Compatibility Checks</h3>
                        <p data-aos="fade-rown">
                            We ensure that your upgraded hardware is fully compatible with existing software applications, minimizing disruptions during the transition. Our team tests and adjusts configurations as needed to maintain a smooth and stable operating environment.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Testing & Validation</h3>
                        <p data-aos="fade-rown">
                            Post-upgrade testing is conducted to validate the performance and stability of your newly upgraded systems. We rigorously test all hardware components and system integrations to ensure that everything is functioning at its best.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Warranty & Support Management</h3>
                        <p data-aos="fade-rown">
                            After upgrading, we assist with managing the warranties and support services for your new hardware. This includes registering new components, tracking warranty periods, and liaising with manufacturers for any support needs, ensuring you have full coverage and peace of mind.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default SecurityHardware
