import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdSecurity } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";
import { FcDataProtection } from "react-icons/fc";

const DevOpsCICD = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>



            <div className="container pt-lg-3 pt-1 mt-lg-4 mt-2 mb-2" >
                <div className="row ">
                    <div className="col-lg-6 d-flex " data-aos="fade-left">
                        <img src="assets/images/pages/DevOpsCICD/devops.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h2 className="ml-lg-1 AI-text-title-one text-lg-left text-center mb-sm-2 mb-1" data-aos="fade-up" style={{ paddingTop: 0 }}>
                            <span className="heading-text-AI-banner ">DevOps & CI/CD </span>
                        </h2>
                        <h3 className="ml-lg-1 textColor">Transform Your Development Process with TechX DevOps & CI/CD Solutions</h3>
                        <p className="ml-lg-1 f_18 textColor text-justify" data-aos="fade-down">At TechX, we empower businesses to accelerate their software delivery cycles and enhance collaboration between development and operations teams. Our DevOps and Continuous Integration/Continuous Deployment (CI/CD) services are designed to streamline your development process, ensuring faster time-to-market, higher quality software, and more reliable releases. </p>
                    </div>
                </div>

            </div>
            <div className="container pt-lg-3 py-sm-2 mt-sm-4 mt-1">
                <h2 className="AI-text-title-one text-center  white-theme-text-AI mb-2" data-aos="fade-right">
                    Continuous   <span className="heading-text-AI-banner">Integration & Deployment </span> (CI/CD)
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-left">Our CI/CD pipelines automate the process of integrating code changes, testing, and deploying applications, ensuring that your software is always in a releasable state. By automating these critical steps, we reduce the risk of errors, improve code quality, and enable faster delivery of new features and updates.</p>
                <div className="row nm-style4 mt-sm-2 mt-1">
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Scalable Solutions</h3>
                        <p data-aos="fade-rown">
                            Our CI/CD pipelines are designed to scale with your business needs. Whether you're deploying to a small set of users or rolling out updates across a global infrastructure, our solutions adapt to your growing demands, ensuring seamless integration and expansion.

                        </p>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Automated Testing</h3>
                        <p data-aos="fade-rown">
                            By incorporating automated testing into the CI/CD pipeline, we catch potential issues early in the development process. This ensures that every release is of the highest quality, reducing the risk of bugs and improving the overall user experience.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Seamless Deployment</h3>
                        <p data-aos="fade-rown">
                            Our CI/CD process automates deployments to minimize downtime and ensure smooth transitions. With built-in rollback capabilities, you can quickly revert to previous versions if any issues arise, maintaining stability and continuity in your operations.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Rapid Iteration</h3>
                        <p data-aos="fade-rown">
                            We enable rapid iteration, allowing your team to release new features and updates frequently. This agility helps you stay competitive in a fast-paced market, responding quickly to user feedback and emerging trends.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Integrated Security</h3>
                        <p data-aos="fade-rown">
                            Security is embedded in every step of our CI/CD pipelines. From automated compliance checks to continuous threat monitoring, our approach ensures that your deployments are secure and that your software remains protected from vulnerabilities.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Collaborative Workflows</h3>
                        <p data-aos="fade-rown">
                            Our CI/CD pipelines foster collaboration across your development, testing, and operations teams. With shared visibility and seamless integration with version control systems, everyone stays aligned, ensuring that projects are delivered on time and to the highest standards.
                        </p>
                    </div>





                </div>
            </div>

            <div className="container py-sm-2 mt-sm-4 mt-2">
                <div className="row ">
                    <div className="col-lg-6 d-flex " data-aos="fade-left">
                        <img src="assets/images/pages/DevOpsCICD/Infrastructure.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-start  align-items-start">
                        <h2 className="mt-lg-0 mt-1  ml-lg-1 AI-text-title-one text-lg-left text-center white-theme-text-AI  mb-1 w-100" data-aos="fade-up" style={{ paddingTop: 0 }}>
                            Infrastructure as Code (IaC)

                        </h2>
                        <p className="ml-lg-1 f_18 textColor text-justify mb-1" data-aos="fade-up">Our Infrastructure as Code (IaC) solutions automate the management of your IT infrastructure, allowing you to define, provision, and update your resources using code. This approach ensures consistency across environments, reduces manual errors, and makes scaling your infrastructure easier.</p>
                        <p className="ml-lg-1 f_18 textColor text-justify mb-1" data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Automation & Efficiency</strong> : Automate the deployment and management of infrastructure to reduce manual tasks and increase efficiency.</p>
                        <p className="ml-lg-1 f_18 textColor text-justify mb-1" data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Scalability & Flexibility</strong> : Easily scale your infrastructure to meet the demands of your applications and services.</p>
                    </div>
                </div>

            </div>




            <div className="container py-sm-2 mt-sm- mt-2 mb-2">
                <h2 className="AI-text-title-one  text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                    Collaboration & Culture
                </h2>
                <div className="row mt-2">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/DevOpsCICD/Collaboration.jpg" className="w-100" data-aos="fade-zoom-in" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">DevOps is not just about tools and processes; it’s about fostering a culture of collaboration and shared responsibility across your teams. We help you build a DevOps culture that encourages communication, collaboration, and continuous learning, enabling your teams to work together more effectively and deliver better software.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Cross-Functional Teams</strong> : Foster collaboration between development, operations, and other teams to break down silos and improve efficiency.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Continuous Learning</strong> : Encourage continuous learning and improvement through feedback loops, retrospectives, and knowledge sharing.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Shared Responsibility</strong> : Promote a culture of shared responsibility, where all team members take ownership of the software delivery process. </p>
                    </div>
                </div>

            </div>

            <div className="container py-sm-2 mt-sm- mt-2 mb-2">
                <h2 className="AI-text-title-one  text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                    Monitoring & Logging
                </h2>
                <div className="row d-lg-flex d-none mt-2">
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">Effective monitoring and logging are essential for maintaining the health and performance of your applications. Our monitoring and logging solutions provide real-time insights into your systems, enabling you to detect and resolve issues before they impact your users.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Real-Time Monitoring</strong> : Gain real-time visibility into the performance of your applications and infrastructure.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Detailed Logging</strong> : Implement detailed logging to track system behavior and troubleshoot issues efficiently.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Proactive Issue Resolution</strong> : Detect and address potential issues before they affect your users, minimizing downtime and disruptions.</p>
                    </div>
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/DevOpsCICD/moniter2.jpg" className="w-100" data-aos="fade-zoom-in" style={{borderRadius:15}}/>
                    </div>
                </div>
                <div className="row d-lg-none d-flex mt-2">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/DevOpsCICD/moniter2.jpg" className="w-100" data-aos="fade-zoom-in" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">Effective monitoring and logging are essential for maintaining the health and performance of your applications. Our monitoring and logging solutions provide real-time insights into your systems, enabling you to detect and resolve issues before they impact your users.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Real-Time Monitoring</strong> : Gain real-time visibility into the performance of your applications and infrastructure.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Detailed Logging</strong> : Implement detailed logging to track system behavior and troubleshoot issues efficiently.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Proactive Issue Resolution</strong> : Detect and address potential issues before they affect your users, minimizing downtime and disruptions.</p>
                    </div>
                </div>

            </div>






            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />

        </>
    )
}

export default DevOpsCICD