import "./styles/EmergingTechIntegration.css";
import ThemeSetting from "./Include/ThemeSetting";
import "slick-carousel/slick/slick-theme.css";
import Cookie from "./Include/Cookie";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CustomSoftware = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(() => {
        Aos.init();
    });

    const services = {
        service1: {
            heading: "Design & Prototyping",
            para: `Once the requirements are clear, we move on to the design and prototyping phase. Here, we create wireframes and mockups to visualize the software’s interface and functionality. Our focus is on creating an intuitive user experience that makes the software easy to use and aligns with your brand identity. We gather your feedback at this stage to ensure that the design meets your expectations before moving forward`,
        },

        service2: {
            heading: "Development & Testing",
            para: `In the development phase, our skilled developers bring the design to life using the latest technologies and best practices. We follow an agile development process, allowing for flexibility and continuous improvement throughout the project. Once the development is complete, we conduct rigorous testing to identify and fix any bugs or issues. Our testing process includes functional, performance, and security testing to ensure that the software is reliable and secure.`,
        },

        service3: {
            heading: "Integration & Deployment",
            para: `After the software has been thoroughly tested, we move on to integration and deployment. We ensure that the new software integrates seamlessly with your existing systems and processes, minimizing disruption to your operations. Our team provides support during the deployment phase to ensure a smooth transition and to address any issues that may arise.`,
        },

        service4: {
            heading: "Maintenance & Support",
            para: `Our commitment to your success doesn’t end with deployment. We offer ongoing maintenance and support services to keep your software up-to-date and running smoothly. Whether you need regular updates, security patches, or enhancements, our team is here to ensure that your software continues to meet your business needs. We also provide support to address any issues that may arise, ensuring minimal downtime and maximum efficiency`,
        },

        service5: {
            heading: "Scalability & Future-Proofing",
            para: `At TechX, we design software with the future in mind. Our solutions are built to scale as your business grows, allowing you to add new features, users, or integrations without compromising performance. We also stay ahead of industry trends and technological advancements, ensuring that your software remains relevant and competitive in a constantly evolving market`,
        },
        service6: {
            heading: "Customization & Flexibility",
            para: `At TechX, we understand that every business has unique needs and challenges. Our Customization & Flexibility services ensure that your software is tailored precisely to your specific requirements. From customizable modules to adaptable interfaces, we provide solutions that can be easily modified as your business evolves. Whether you need specific features, integrations with third-party systems.`,
        },
    };
    return (
        <>
            <div className="heading container">
                <div className="heading-container">

                    <h1 className="heading-2">
                        <span className="h2_color">Custom Software</span>
                        <span className="heading-logo"> Development</span>
                    </h1>
                    <h3 className="textColor">
                        At TechX, we specialize in delivering tailored software solutions that are designed to meet the unique needs of your business.
                    </h3>
                </div>

                <div className="heading-image" data-aos="fade-left">
                    <img
                        src="assets/images/pages/CustomSoftware/softcustom.jpg"
                        alt=""
                    ></img>
                </div>
            </div>
            <div className="container">

                <div className="discription mb-3" data-aos="fade-up" data-aos-duration="3000" style={{ height: '100%' }}>
                    <p className="dis-p-1 textColor">
                        Our Custom Software Development services focus on creating robust, scalable, and user-friendly applications that drive efficiency, enhance productivity, and support your business goals. Whether you need a complex enterprise system or a simple mobile application, our team of experts works closely with you to understand your requirements and deliver a solution that fits perfectly with your operations.
                    </p>
                    <div className="des-para-2 textColor">
                        <h3>Requirement Analysis</h3>
                        <p>
                            Our development process begins with a thorough requirement analysis, where we work with you to identify your business needs, challenges, and objectives. This phase is crucial as it sets the foundation for the entire project, ensuring that the final product aligns with your vision. We gather detailed insights into your workflows, user expectations, and technical constraints to create a comprehensive project plan.
                        </p>
                    </div>


                </div>
            </div>
            <div className="container">

                <div className="services" data-aos="fade-up" data-aos-duration="3000">
                    <div className="services-container">
                        <div className="services-1 service" style={{ width: "auto" }}>
                            <h1>{services.service1.heading}</h1>
                            <p className="text-left" style={{ marginLeft: '18px' }}>{services.service1.para}</p>
                        </div>

                        <div className="services-2 service " style={{ width: "auto" }}>
                            <h1>{services.service2.heading}</h1>
                            <p className="text-left" style={{ marginLeft: '18px' }}>{services.service2.para}</p>
                        </div>

                        <div className="services-3 service" style={{ width: "auto" }}>
                            <h1>{services.service3.heading}</h1>
                            <p className="text-left" style={{ marginLeft: '18px' }}>{services.service3.para}</p>
                        </div>

                        <div className="services-4 service" style={{ width: "auto" }}>
                            <h1>{services.service4.heading}</h1>
                            <p className="text-left" style={{ marginLeft: '18px' }}>{services.service4.para}</p>
                        </div>
                        <div className="services-4 service" style={{ width: "auto" }}>
                            <h1>{services.service5.heading}</h1>
                            <p className="text-left" style={{ marginLeft: '18px' }}>{services.service5.para}</p>
                        </div>
                        <div className="services-4 service" style={{ width: "auto" }}>
                            <h1>{services.service6.heading}</h1>
                            <p className="text-left" style={{ marginLeft: '18px' }}>{services.service6.para}</p>
                        </div>


                    </div>
                </div>

            </div>

            <div className="container textColor mt-lg-5 mt-3 mb-sm-5 mb-3" data-aos="fade-up" data-aos-duration="3000">
                <h1 className="text-center">
                    Join the <span className="container2-heading" style={{ color: 'red' }}>TECHX </span>
                    Revolution
                </h1>
                <p className="text-justify">
                In a world where technology continually evolves, TECHX is at the forefront of transformation. Partner with us to experience a revolution where cutting-edge technology meets visionary solutions. At TECHX, we don’t just follow trends—we create them. Our commitment goes beyond service; it's a pledge to engineer your future with precision, innovation, and excellence. Embrace the future with TECHX and let us guide you through a journey where every technological leap is a step toward your success
                </p>

            </div>

  

            <ThemeSetting />
        </>
    )
}

export default CustomSoftware