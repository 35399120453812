import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdSecurity } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";
import { FcDataProtection } from "react-icons/fc";


const HardwareService = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>



            <div className="container pt-lg-3 pt-1 mt-lg-4 mt-2 mb-2" >
                <div className="row ">
                    <div className="col-lg-6 d-flex " data-aos="fade-left">
                        <img src="assets/images/pages/HardwareService/banner.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h2 className="ml-lg-1 AI-text-title-one text-lg-left text-center mb-sm-2 mb-1" data-aos="fade-up" style={{ paddingTop: 0 }}>
                            <span className="heading-text-AI-banner ">Hardware </span> as a Service (HaaS)
                        </h2>
                        <p className="ml-lg-1 f_18 textColor text-justify" data-aos="fade-down">At TechX, we offer Hardware as a Service (HaaS)—a flexible, cost-effective solution that provides businesses with the latest hardware without the burden of large upfront costs. Our HaaS model allows you to lease cutting-edge hardware, managed and maintained by our expert team, ensuring that your technology infrastructure remains up-to-date, secure, and scalable to meet your evolving business needs. </p>
                    </div>
                </div>

            </div>
            <div className="container pt-lg-3 py-sm-2 mt-sm-4 mt-1">
                <h2 className="AI-text-title-one text-center  white-theme-text-AI mb-2" data-aos="fade-right">
                    Flexible  <span className="heading-text-AI-banner">Hardware </span> Leasing
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-left">Our HaaS model offers flexible leasing options that allow you to scale your hardware resources up or down based on your business requirements. Whether you need additional servers, storage devices, or networking equipment, we provide the hardware you need without the capital expenditure.</p>
                <div className="row nm-style4 mt-sm-2 mt-1">
                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Scalable Solutions</h3>
                        <p data-aos="fade-rown">
                            <li>Adaptable hardware upgrades</li>
                            <li>Future-proof infrastructure</li>
                            <li>On-demand expansion</li>
                            <li>Seamless integration</li>
                            <li>Flexible capacity management</li>
                            <li>Growth-aligned strategy</li>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">No Upfront Costs</h3>
                        <p data-aos="fade-rown">
                            <li>Cost-efficient leasing</li>
                            <li>Financial flexibility</li>
                            <li>Predictable budgeting</li>
                            <li>Reduced financial risk</li>
                            <li>Cash flow management</li>
                            <li>Investment allocation</li>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Tailored Packages</h3>
                        <p data-aos="fade-rown">
                            <li>Custom hardware selection</li>
                            <li>Service level agreements (SLAs)</li>
                            <li>Industry-specific solutions</li>
                            <li> Full-service support</li>
                            <li>  Hardware variety</li>
                            <li> Flexible contract options</li>

                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Easy Upgrades</h3>
                        <p data-aos="fade-rown">
                            <li>Access to the latest hardware</li>
                            <li>Hassle-free upgrade process</li>
                            <li>Continuous technology refresh</li>
                            <li> Minimal disruption during upgrades</li>
                            <li> Compatibility checks for smooth integration</li>
                            <li> Efficient deployment of new hardware</li>
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Flexible Contract Terms</h3>
                        <p data-aos="fade-rown">
                            <li>Short-term and long-term options</li>
                            <li> Customizable agreement lengths</li>
                            <li>Adjustable leasing terms</li>
                            <li> No lock-in periods</li>
                            <li>Easy contract renewal</li>
                            <li>Terms aligned with business needs</li>
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Minimal Downtime</h3>
                        <p data-aos="fade-rown">
                            <li>Quick hardware replacement</li>
                            <li> Proactive maintenance scheduling</li>
                            <li>Rapid deployment of backup systems</li>
                            <li>24/7 technical support</li>
                            <li>Streamlined upgrade process</li>
                            <li>Continuous monitoring for issues</li>
                        </p>
                    </div>



                </div>
            </div>

            <div className="container py-sm-2 mt-sm-4 mt-2">
                <div className="row ">
                    <div className="col-lg-6 d-flex " data-aos="fade-left">
                        <img src="assets/images/pages/HardwareService/managed.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-start  align-items-start">
                        <h2 className="mt-lg-0 mt-1  ml-lg-1 AI-text-title-one text-lg-left text-center white-theme-text-AI  mb-1 w-100" data-aos="fade-up" style={{ paddingTop: 0 }}>
                            Managed Services
                        </h2>
                        <p className="ml-lg-1 f_18 textColor text-justify mb-1" data-aos="fade-up">With our HaaS offering, you gain access to fully managed services, ensuring that your hardware is always running at peak performance.</p>
                        <p className="ml-lg-1 f_18 textColor text-justify mb-1" data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Proactive Maintenance</strong> : We handle all maintenance tasks, including firmware updates, hardware repairs, and regular inspections, so you can focus on your core business operations.</p>
                        <p className="ml-lg-1 f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Lifecycle Management</strong> : We manage the entire lifecycle of your hardware, from installation and configuration to eventual upgrades and disposal, ensuring seamless transitions at every stage.</p> </div>
                </div>

            </div>

            <div className="container py-sm-2">
                <h2 className="AI-text-title-one text-center mt-lg-5 mt-sm-3 mt-1 white-theme-text-AI mb-2" data-aos="fade-down">
                    Enhanced <span className="heading-text-AI-banner ">Security & Compliance </span>
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-up">Security is a top priority in our HaaS model. We ensure that all hardware is equipped with the latest security features and compliant with industry regulations.</p>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-2 mt-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box  ">
                            <div className="AI-circle-box">
                                <MdSecurity className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Secure Hardware
                            </h3>
                            <p className="AI-text-title-three mt-1">
                                Our HaaS solutions include hardware with built-in security features, such as encryption, firewalls, and intrusion detection systems, to protect your data and network.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2 mt-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box ">
                            <div className="AI-circle-box">
                                <GrCompliance className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Regulatory Compliance
                            </h3>
                            <p className="AI-text-title-three mt-1">We ensure that all hardware provided through our HaaS model complies with relevant industry regulations, such as GDPR, HIPAA, and PCI-DSS, keeping your business compliant and secure. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2 mt-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box ">
                            <div className="AI-circle-box">
                                <FcDataProtection className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Data Protection
                            </h3>
                            <p className="AI-text-title-three mt-1">Regular backups and disaster recovery options are included in our HaaS packages, ensuring that your data is protected and recoverable in the event of a hardware failure or security breach
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="container py-sm-2 mt-sm- mt-2 mb-2">
                <h2 className="AI-text-title-one  text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                    Cost Predictability
                </h2>
                <div className="row mt-2">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/HardwareService/cost.png" className="w-100" data-aos="fade-zoom-in" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">HaaS provides cost predictability with a fixed monthly fee, allowing you to budget more effectively.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Budget-Friendly</strong> : We start with a thorough understanding of your business goals and technology requirements.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Transparent Pricing</strong> : We offer clear, transparent pricing with no hidden fees, so you know exactly what you're paying for.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Operational Efficiency</strong> : By converting capital expenses into operational expenses, HaaS helps you optimize your budget and improve cash flow management.                        </p>
                    </div>
                </div>

            </div>

            <div className="container py-sm-2 mt-sm- mt-2 mb-2">
                <h2 className="AI-text-title-one  text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                    Seamless Integration
                </h2>
                <div className="row d-lg-flex d-none mt-2">
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">Our HaaS solutions are designed to integrate seamlessly with your existing infrastructure, ensuring a smooth transition and uninterrupted business operations.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Custom Integration</strong> : We work closely with your IT team to ensure that new hardware integrates smoothly with your current systems and software, minimizing disruptions.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Compatibility Assurance</strong> : Before deployment, we thoroughly test all hardware for compatibility with your existing infrastructure, ensuring that everything works together seamlessly.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Ongoing Optimization</strong> : We continuously optimize your hardware setup to ensure that it remains aligned with your business goals and technological advancements.</p>
                    </div>
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/HardwareService/ambition.png" className="w-100" data-aos="fade-zoom-in" />
                    </div>
                </div>
                <div className="row d-lg-none d-flex mt-2">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/HardwareService/ambition.png" className="w-100" data-aos="fade-zoom-in" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">Our HaaS solutions are designed to integrate seamlessly with your existing infrastructure, ensuring a smooth transition and uninterrupted business operations.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Custom Integration</strong> : We work closely with your IT team to ensure that new hardware integrates smoothly with your current systems and software, minimizing disruptions.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Compatibility Assurance</strong> : Before deployment, we thoroughly test all hardware for compatibility with your existing infrastructure, ensuring that everything works together seamlessly.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Ongoing Optimization</strong> : We continuously optimize your hardware setup to ensure that it remains aligned with your business goals and technological advancements.</p>
                    </div>
                </div>

            </div>






            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />

        </>
    )
}

export default HardwareService