import ThemeSetting from "./Include/ThemeSetting"
import { IoHardwareChip } from "react-icons/io5";
import { GrValidate } from "react-icons/gr";
import { FaHandsHelping } from "react-icons/fa";
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TechAssessments = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div className="container mt-2 mb-1" >
                <div className="row">
                    <div className="col-lg-6 py-1" data-aos="fade-left">
                        <img src="assets/images/pages/TechAssessments/test1.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h2 className="AI-text-title-one text-lg-left text-center mb-sm-2 mb-1" data-aos="fade-up">
                            <span className="heading-text-AI-banner ">Tech  </span>Assessments
                        </h2>
                        <p className="f_18 textColor text-justify" data-aos="fade-down">At TechX, our Tech Assessments are designed to provide a thorough evaluation of your current technology landscape. Whether you're looking to optimize existing systems, identify potential vulnerabilities, or plan for future growth, our expert assessments give you the insights you need to make informed decisions.</p>
                    </div>
                </div>

            </div>
            <div className="container py-sm-1">
                <h2 className="AI-text-title-one text-center mt-md-2 mt-1 white-theme-text-AI mb-2" data-aos="fade-down">
                    Infrastructure & Security  <span className="heading-text-AI-banner ">Assessment </span>
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-up">Our combined Infrastructure and Security Assessment provides a thorough evaluation of your IT environment, focusing on performance, scalability, reliability, and security. We examine every component of your infrastructure—from servers and networks to storage and security systems—while also conducting a comprehensive review of your security protocols, including firewalls, encryption, and access controls. By identifying areas for improvement and potential vulnerabilities, we help you make informed decisions about upgrades, optimizations, and protective measures, ensuring your IT environment is both robust and secure.</p>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3 ">
                            <div className="AI-circle-box">
                                <IoHardwareChip className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Performance Analysis
                            </h3>
                            <p className="AI-text-title-three mt-1">
                                We assess the efficiency of your IT systems, identifying bottlenecks and recommending optimizations to enhance overall performance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3">
                            <div className="AI-circle-box">
                                <GrValidate className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Security Vulnerability Check
                            </h3>
                            <p className="AI-text-title-three mt-1">Our assessment identifies potential security risks within your infrastructure and offers actionable solutions to strengthen your defenses. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3">
                            <div className="AI-circle-box">
                                <FaHandsHelping className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                                Scalability Planning
                            </h3>
                            <p className="AI-text-title-three mt-1">We evaluate the scalability of your current systems and provide strategic guidance for future expansions to support business growth.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container py-sm-2 mt-sm-4 mt-2">
                <div className="row">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/TechAssessments/app.png" className="w-100" data-aos="fade-zoom-in" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-start py-lg-2 align-items-start">
                        <h2 className="AI-text-title-one text-lg-left text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                            Application Assessment
                        </h2>
                        <p className="f_18 textColor text-justify mb-1" data-aos="fade-up">Our Application Assessment focuses on evaluating the performance, usability, and integration of your business applications. We analyze how well your software solutions support your business processes, identifying bottlenecks, inefficiencies, and compatibility issues. Whether you're using off-the-shelf software or custom applications, our assessment provides insights into how to optimize their performance, improve user experience, and ensure seamless integration with other systems. We also assess the scalability of your applications, ensuring they can grow with your business and adapt to future need.</p>
                        <p className="f_18 textColor text-justify mb-1" data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>User Experience Optimization</strong> : We evaluate the user interface and overall experience of your applications, providing recommendations to enhance usability and drive higher user satisfaction.</p>
                    </div>
                </div>

            </div>
            <div className="container py-sm-2 mt-sm- mt-2">
                <h2 className="AI-text-title-one text-center  white-theme-text-AI mb-2" data-aos="fade-right">
                    <span className="heading-text-AI-banner">Cloud  </span>Readiness Assessment
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-left">As businesses increasingly transition to cloud-based solutions, it's crucial to ensure that your IT environment is prepared for the shift. Our Cloud Readiness Assessment evaluates your current infrastructure, applications, and data to identify any potential challenges or gaps. We provide a clear roadmap to guide your successful cloud migration, enabling your business to harness the full benefits of cloud computing, including enhanced scalability, flexibility, and cost efficiency. </p>
                <div className="row nm-style4 mt-sm-2 mt-1">
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Infrastructure Evaluation</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>

                                <li>Assess current hardware and software compatibility with cloud platforms</li>
                                <li>Identify necessary upgrades for seamless cloud integration</li>
                                <li>Evaluate network performance and bandwidth requirements</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Data Migration Planning</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Develop a strategy for transferring data securely to the cloud                            </li>
                                <li>Assess data integrity and minimize downtime during migration</li>
                                <li>Plan for data backup and recovery in the cloud environment</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Security & Compliance Review</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Analyze existing security measures and cloud security requirements</li>
                                <li>Ensure compliance with industry standards and regulations</li>
                                <li>Implement strategies for data protection and privacy in the cloud</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Application Compatibility</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Evaluate application readiness for cloud deployment</li>
                                <li>Identify necessary modifications for cloud-based operation</li>
                                <li>Ensure seamless integration with cloud services and platforms</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Cost-Benefit Analysis</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Compare the financial implications of cloud migration</li>
                                <li>Assess potential cost savings and ROI from cloud adoption</li>
                                <li>Plan for ongoing cloud management and operational costs</li>
                            </ul>
                        </p>
                    </div>


                </div>
            </div>
            <div className="container py-sm-2 mt-sm- mt-2 mb-2">
                <h2 className="AI-text-title-one  text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                    Performance Assessment
                </h2>
                <div className="row mt-2">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/TechAssessments/Performance.png" className="w-100" data-aos="fade-zoom-in" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">Ensuring that your IT systems perform optimally is essential for maintaining business continuity and efficiency. Our Performance Assessment examines the speed, reliability, and capacity of your IT systems, identifying any issues that may be hindering performance. We provide detailed recommendations on how to optimize your systems, from upgrading hardware to tuning software configurations, ensuring that your technology infrastructure delivers the performance your business needs.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>System Benchmarking</strong> : We conduct comprehensive benchmarking to evaluate the current performance levels of your IT systems against industry standards.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Optimization Strategy</strong> : Based on our findings, we develop a tailored optimization strategy, focusing on hardware upgrades, software tuning, and resource management.</p>
                    </div>
                </div>

            </div>



            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default TechAssessments