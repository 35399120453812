
import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const EnterpriseSolutions = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>

            <div className="container py-md-5 py-2">
                <div className="row d-lg-flex d-none">
                    <div className="col-lg-6  d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40 mr-xl-2" data-aos="fade-up" >Enterprise Solutions</h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-down">
                            At TechX, we empower businesses with cutting-edge Enterprise Solutions that drive operational efficiency, scalability, and innovation. Our comprehensive suite of services is designed to address the complex needs of large organizations, providing robust and scalable technology solutions that align with your business objectives.  </p>
                    </div>
                    <div className="col-lg-6 p-lg-0  px-md-2 d-flex" data-aos="fade-left">
                        <img src="assets/images/pages/EnterpriseSolutions/enterprise.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row d-lg-none d-flex">

                    <div className="col-lg-6 p-lg-0  px-md-2 d-flex" data-aos="fade-left">
                        <img src="assets/images/pages/EnterpriseSolutions/enterprise.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6  d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40 ml-xl-2" data-aos="fade-up" >Enterprise Solutions </h3>
                        <p className="textColor f_18 text-justify ml-xl-2" data-aos="fade-down">
                            At TechX, we empower businesses with cutting-edge Enterprise Solutions that drive operational efficiency, scalability, and innovation. Our comprehensive suite of services is designed to address the complex needs of large organizations, providing robust and scalable technology solutions that align with your business objectives.  </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">
                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about f_25 mb-1 w-100 text-center" data-aos="fade-right" >Enterprise Resource Planning (ERP) Solutions</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                        TechX's ERP solutions integrate all facets of your business into a unified system, enhancing visibility and control over your operations  </p>
                    <div className="col-lg-6 d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-right">
                        <img src="assets/images/pages/EnterpriseSolutions/erp.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Streamlined Operations</span> : Our ERP systems centralize business processes, allowing for seamless communication and collaboration across departments.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Customizable Modules</span> : We offer customizable modules tailored to your specific business needs, from finance and HR to supply chain and inventory management.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Real-Time Data Analytics</span> : Gain real-time insights into your business performance with powerful analytics tools, enabling data-driven decision-making.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Scalability</span> : Our ERP solutions are designed to grow with your business, supporting additional users, modules, and locations as needed.</p>
                    </div>
                </div>
                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about mt-lg-5 mt-3 f_25 mb-1 w-100 text-center" data-aos="fade-right" >Customer Relationship Management (CRM) Systems                    </h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                        Enhance customer satisfaction and loyalty with our advanced CRM systems, designed to manage your customer interactions and relationships more effectively.  </p>
                    <div className="col-lg-6 d-lg-flex d-none flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">360-Degree Customer View</span> : Our CRM systems provide a comprehensive view of customer data, enabling personalized marketing and sales strategies.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Automation of Sales Processes</span> : Streamline sales workflows through automation, from lead generation to closing deals, improving sales team productivity.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Customer Service Optimization</span> : Improve customer service with integrated tools for managing inquiries, support tickets, and feedback.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Data-Driven Insights</span> : Utilize powerful analytics to understand customer behavior, identify trends, and make informed business decisions.</p>
                    </div>
                    <div className="col-lg-6 d-lg-flex d-none p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/EnterpriseSolutions/crm.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-lg-none d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/EnterpriseSolutions/crm.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-lg-none d-flex flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">360-Degree Customer View</span> : Our CRM systems provide a comprehensive view of customer data, enabling personalized marketing and sales strategies.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Automation of Sales Processes</span> : Streamline sales workflows through automation, from lead generation to closing deals, improving sales team productivity.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Customer Service Optimization</span> : Improve customer service with integrated tools for managing inquiries, support tickets, and feedback.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Data-Driven Insights</span> : Utilize powerful analytics to understand customer behavior, identify trends, and make informed business decisions.</p>
                    </div>
                </div>




            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">

                <div className="row  mb-md-4 mb-2 ">
                    <div className="col-lg-6 mt-lg-4 mt-2 d-flex p-lg-0 px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/EnterpriseSolutions/ecm.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 mt-lg-4 mt-2 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className="color-about  f_25 mb-1 text-center" data-aos="fade-left">Enterprise Content Management (ECM)                        </h3>
                        <p className="textColor f_18 text-justify ml-xl-2" data-aos="fade-left">Organize, manage, and distribute your business-critical content with our Enterprise Content Management solutions.
                            <p className="f_18 textColor text-justify mb-1  " data-aos="fade-up">Centralize storage and management of documents, making it easy to retrieve and share information across your organization. Automate document-centric workflows, reducing manual tasks and improving efficiency. Ensure that your content management processes adhere to industry regulations and maintain data security. Facilitate collaboration with tools that allow teams to work together on content creation, editing, and approval processes. Seamlessly integrate ECM with other enterprise systems such as ERP and CRM for a unified business environment.

                            </p>

                        </p>
                    </div>
                </div>

                <div className="row mb-2 mt-lg-5 d-lg-flex d-none">
                    <div className="col-lg-6  mt-lg-5  d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1 mr-xl-2" data-aos="fade-left">Business Intelligence & Analytics </h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            Turn data into actionable insights with TechX's Business Intelligence & Analytics solutions, helping you make informed decisions and stay ahead of the competition.
                        </p>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            Consolidate data from multiple sources into a single platform, providing a holistic view of your business. Create customizable dashboards that provide real-time insights into key performance indicators (KPIs).
                            Leverage advanced analytics tools to predict future trends and opportunities, enabling proactive decision-making. Automate the generation and distribution of reports, ensuring that stakeholders receive timely and accurate information.
                            Our BI solutions are designed to handle large volumes of data, making them suitable for organizations of all sizes.
                        </p>
                    </div>
                    <div className="col-lg-6 d-flex mt-lg-5  p-lg-0  px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/EnterpriseSolutions/intellegence.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row mb-2 d-flex d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0   px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/EnterpriseSolutions/intellegence.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about mb-1  f_25" data-aos="fade-left">Business Intelligence & Analytics</h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            Turn data into actionable insights with TechX's Business Intelligence & Analytics solutions, helping you make informed decisions and stay ahead of the competition.
                        </p>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                        Consolidate data from multiple sources into a single platform, providing a holistic view of your business. Create customizable dashboards that provide real-time insights into key performance indicators (KPIs).
                            Leverage advanced analytics tools to predict future trends and opportunities, enabling proactive decision-making. Automate the generation and distribution of reports, ensuring that stakeholders receive timely and accurate information.
                            Our BI solutions are designed to handle large volumes of data, making them suitable for organizations of all sizes.
                        </p>
                    </div>
                </div>

            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">

                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about f_25 mb-1 w-100 text-center" data-aos="fade-right" >Cloud Solutions for Enterprise</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                    TechX's Cloud Solutions offer scalable and secure infrastructure that meets the demands of modern enterprises. </p>
                    <div className="col-lg-6 d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/ITInfrastructure/network.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Flexible Infrastructure</span> : Choose from a range of cloud deployment models, including public, private, and hybrid, to match your business needs.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Cost Efficiency</span> : Reduce capital expenditures by moving to a cloud-based model, paying only for the resources you use.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Scalability</span> : Easily scale your cloud resources up or down based on your business requirements, ensuring optimal performance.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Security & Compliance</span> : Protect your data with advanced security measures and ensure compliance with industry regulations.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Disaster Recovery</span> : Implement robust disaster recovery plans with cloud-based solutions, minimizing downtime and data loss in case of emergencies.</p>
                    </div>
                </div>
            </div>






            <div className="se-head mt-sm-3 mt-2 mb-5">
                <h3 className="se-title-1">FAQS</h3>
                <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                <Link to="/support"
                    className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                >
                    Support
                </Link>
            </div>
            <ThemeSetting />

        </>
    )
}

export default EnterpriseSolutions