import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const HardwareProcurement = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div className="container py-3">
                <div className="row">

                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/HardwareProcurement/headimg.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40" data-aos="fade-up" >Hardware Procurement Solutions</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down">
                            At TechX, we provide end-to-end hardware procurement services designed to meet the unique needs of your business.From selecting the right vendors to sourcing top-quality products and creating custom hardware solutions, we ensure that your technology infrastructure is built on a solid foundation.
                        </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-3">
                <div className="row mb-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-right" >Vendor Selection</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down">
                            Choosing the right vendors is crucial to ensuring the reliability and quality of your hardware. At TechX, we leverage our extensive network of trusted partners to select vendors that align with your business requirements. Our rigorous selection process includes evaluating vendor credibility, product quality, and after-sales support, ensuring you receive the best value and performance.
                        </p>
                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/HardwareProcurement/vendorselection.png" className="w-100" />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/HardwareProcurement/vendorselection.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-right" >Vendor Selection</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down" >
                            Choosing the right vendors is crucial to ensuring the reliability and quality of your hardware. At TechX, we leverage our extensive network of trusted partners to select vendors that align with your business requirements. Our rigorous selection process includes evaluating vendor credibility, product quality, and after-sales support, ensuring you receive the best value and performance.
                        </p>
                    </div>
                </div>


                <div className="row mb-2">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/HardwareProcurement/ProductSourcing.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className="color-about f_25 mb-1" data-aos="fade-left">Product Sourcing</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">We understand that the right hardware can make all the difference in your business operations. TechX specializes in sourcing a wide range of hardware products from leading manufacturers. Whether you need the latest servers, networking equipment, or specialized peripherals, our team ensures you have access to the best products that fit your budget and technical requirements.

                        </p>
                    </div>
                </div>

                <div className="row mb-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-left">Bulk Purchasing</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">
                            TechX offers competitive pricing on bulk hardware purchases, helping your business save on costs without compromising quality. Our bulk purchasing service is designed to provide businesses of all sizes with the necessary equipment to scale their operations efficiently. We negotiate directly with manufacturers and distributors to secure the best deals, passing the savings directly to you.
                        </p>
                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/HardwareProcurement/BulkPurchasing.png" className="w-100" />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/HardwareProcurement/BulkPurchasing.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about mb-1  f_25" data-aos="fade-left">Bulk Purchasing</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">
                            TechX offers competitive pricing on bulk hardware purchases, helping your business save on costs without compromising quality. Our bulk purchasing service is designed to provide businesses of all sizes with the necessary equipment to scale their operations efficiently. We negotiate directly with manufacturers and distributors to secure the best deals, passing the savings directly to you.
                        </p>
                    </div>
                </div>

                <div className="row mb-2 ">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/HardwareProcurement/CustomHardware.png" className="w-100" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="mb-1 color-about f_25" data-aos="fade-right">Custom Hardware Solutions</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-right">Every business has unique needs, and off-the-shelf hardware isn't always the best fit. TechX provides custom hardware solutions tailored to your specific requirements. Our team works closely with you to design and procure hardware that integrates seamlessly into your existing systems, ensuring optimal performance and scalability. Whether you need custom-built servers, workstations, or specialized equipment, TechX delivers solutions that are as unique as your business.</p>
                    </div>
                </div>
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link  to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default HardwareProcurement