import ThemeSetting from "./Include/ThemeSetting"
import { IoHardwareChip } from "react-icons/io5";
import { FaClipboardList } from "react-icons/fa";
import { GrValidate } from "react-icons/gr";
import { FaHandsHelping } from "react-icons/fa";
import Aos from "aos";
import { RiSecurePaymentFill } from "react-icons/ri";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ItGovernance = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div className="container mt-2 mb-1" >
                <div className="row">
                    <div className="col-lg-6 py-1" data-aos="fade-left">
                        <img src="assets/images/pages/ItGovernance/2950869.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h2 className="AI-text-title-one text-lg-left text-center mb-sm-2 mb-1" data-aos="fade-up">
                            <span className="heading-text-AI-banner ">IT  </span>Governance & Risk
                        </h2>
                        <p className="f_18 textColor text-justify" data-aos="fade-down">At TechX, we understand that effective IT governance and risk management are essential to maintaining the integrity, security, and performance of your technology infrastructure. Our comprehensive IT Governance & Risk services ensure that your IT operations align with your business goals, while also safeguarding against potential threats. We help you establish clear governance frameworks, manage IT-related risks, and comply with regulatory requirements.</p>
                    </div>
                </div>

            </div>
            <div className="container py-sm-2 mt-sm- mt-2">
                <h2 className="AI-text-title-one text-center  white-theme-text-AI mb-2" data-aos="fade-right">
                    <span className="heading-text-AI-banner">Governance & Risk  </span>Management
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-left">Our Governance & Risk Management service integrates the development of robust IT governance frameworks with comprehensive risk assessment strategies. We work closely with you to define clear roles, responsibilities, and processes within your organization, ensuring that IT operations are aligned with your business objectives and that decision-making is both consistent and transparent. Simultaneously, we identify, analyze, and mitigate IT-related risks, crafting tailored risk management strategies that address current vulnerabilities and anticipate future threats. This holistic approach guarantees that your IT environment is not only well-governed but also resilient to challenges. </p>
                <div className="row nm-style4 mt-sm-2 mt-1">
                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Role Definition</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>

                                <li>Clarify responsibilities across IT teams</li>
                                <li>Establish accountability structures</li>
                                <li>Align roles with business goals</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Policy Development</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Create comprehensive IT policies</li>
                                <li>Ensure alignment with industry standards</li>
                                <li>Regularly review and update policies</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Decision-Making Processes</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Standardize IT decision-making protocols</li>
                                <li>Foster transparent communication</li>
                                <li>Support data-driven decisions</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Risk Identification</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Conduct thorough risk assessments</li>
                                <li>Uncover vulnerabilities in systems and processes</li>
                                <li>Prioritize risks based on potential impact</li>
                            </ul>
                        </p>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Mitigation Strategies</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Develop actionable risk mitigation plans</li>
                                <li>Implement proactive security measures</li>
                                <li>Continuously monitor and adjust strategies</li>
                            </ul>
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Compliance Alignment</h3>
                        <p data-aos="fade-rown">
                            <ul style={{ listStyle: "disc", paddingLeft: 16 }}>
                                <li>Ensure governance frameworks meet regulatory standards</li>
                                <li>Align risk management with compliance requirements</li>
                                <li>Prepare for audits and certifications</li>
                            </ul>
                        </p>
                    </div>


                </div>
            </div>

            <div className="container py-sm-2 mt-sm-4 mt-2">
                <div className="row">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/ItGovernance/4400262.jpg" className="w-100" data-aos="fade-zoom-in" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-start py-lg-2 align-items-start">
                        <h2 className="AI-text-title-one text-lg-left text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                        Compliance & Regulatory Support
                        </h2>
                        <p className="f_18 textColor text-justify mb-1" data-aos="fade-up">Navigating the complex landscape of IT regulations and industry standards can be challenging. TechX offers Compliance & Regulatory Support to help your organization stay ahead of regulatory changes and meet compliance requirements. We guide you through the process of achieving and maintaining compliance with relevant standards, such as GDPR, HIPAA, and ISO certifications. Our proactive approach minimizes the risk of non-compliance and protects your business from legal and financial repercussions.</p>
                    </div>
                </div>

            </div>


            <div className="container py-sm-1">
                <h2 className="AI-text-title-one text-center mt-md-2 mt-1 white-theme-text-AI mb-2" data-aos="fade-down">
                Continuous   <span className="heading-text-AI-banner ">Monitoring & Improvement </span>
                </h2>
                <p className="f_18 textColor text-justify" data-aos="fade-up">At TechX, we understand that effective IT governance and risk management require constant attention. Our Continuous Monitoring & Improvement services ensure that your IT environment stays secure, compliant, and adaptable to emerging risks and evolving business needs. By regularly assessing the effectiveness of your governance frameworks and risk management strategies, we help you implement necessary improvements, keeping your operations aligned with your long-term goals.</p>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3 ">
                            <div className="AI-circle-box">
                                <GrValidate className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                            Proactive Threat Detection
                            </h3>
                            <p className="AI-text-title-three mt-1">
                            We continuously monitor your systems to identify and address potential threats before they escalate, ensuring your IT environment remains secure.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3">
                            <div className="AI-circle-box">
                                <FaClipboardList  className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                            Governance Effectiveness Reviews
                            </h3>
                            <p className="AI-text-title-three mt-1">Regular assessments of your governance frameworks help us pinpoint areas for improvement, ensuring that your IT operations stay aligned with business objectives. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2  d-flex align-items-stretch" data-aos="fade-zoom-out">
                        <div className=" h_100 hardware_box mt-3">
                            <div className="AI-circle-box">
                                <RiSecurePaymentFill  className="icon-AI-card" />
                            </div>
                            <h3 className="AI-text-title-two mt-1 white-theme-color" style={{ color: "#2edaf1" }}>
                            Adaptive Risk Management
                            </h3>
                            <p className="AI-text-title-three mt-1">As your business grows and changes, we adapt your risk management strategies to address new challenges, ensuring ongoing resilience and compliance.
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="container py-sm-2 mt-sm- mt-2 mb-2">
                <h2 className="AI-text-title-one  text-center   white-theme-text-AI mb-2" data-aos="fade-up">
                Incident Response Planning
                </h2>
                <div className="row mt-2">
                    <div className="col-lg-6 d-flex  justify-content-center align-items-center" data-aos="fade-zoom-in">
                        <img src="assets/images/pages/ItGovernance/Response.png" className="w-100" data-aos="fade-zoom-in" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column  justify-content-center align-items-center" data-aos="fade-up">
                        <p className="f_18 textColor text-justify mb-1">Preparing for potential IT incidents is critical to minimizing their impact. Our Incident Response Planning services ensure that your organization is ready to respond quickly and effectively to any IT-related emergencies. We work with you to develop detailed incident response plans that outline procedures for identifying, containing, and resolving incidents, from cyberattacks to system failures. With a well-structured plan in place, you can reduce downtime, protect sensitive data, and maintain business continuity in the face of unexpected events.</p>
                        <p className="f_18 textColor text-justify mb-1"><strong style={{ color: "#2edaf1" }}>Rapid Incident Detection</strong> : Our approach includes setting up advanced monitoring tools that enable rapid detection of incidents, allowing your team to respond immediately and mitigate risks.</p>
                    </div>
                </div>

            </div>



            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default ItGovernance