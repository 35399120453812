/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from "react-router-dom";
import Cookie from './Include/Cookie';
import ThemeSetting from './Include/ThemeSetting';
import { FaLock, FaUbuntu, FaRegSun, FaHeadset, FaDollyFlatbed, FaHome ,FaPhoneSquareAlt   } from 'react-icons/fa'

import './styles/Contact.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


// import useLoader from "../Services/useLoader";
export const Contact = (props) => {
    const baseUrl = location.href.includes("trainingncr.info")
        ? `http://trainingncr.info/techx/assets/images`
        : `/assets/images`;

    const [activeFilter, setActiveFilter] = useState('ArtificialIntelligence');
    const handleTabClick = (filter) => {
        setActiveFilter(filter);
    };
    const handleFAQClick = (e) => {
        const questions = document.querySelectorAll('.q');

        questions.forEach(question => {
            if (question !== e.currentTarget) {
                question.classList.remove('open');
                question.querySelector('.q-b').style.display = 'none';

            }
        });
        e.currentTarget.classList.toggle('open');
        const qb = e.currentTarget.querySelector('.q-b');

        if (qb) {
            qb.style.display = qb.style.display === 'block' ? 'none' : 'block';
        }

    };
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const targetRef = useRef(null);


    const handleDivClick = (category) => {
        handleTabClick(category);

        // Scroll to target div
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className="se-i-contact" style={{
                backgroundImage: `url(${baseUrl}/pages/contact/visu-bg.png)`
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 left-col d-flex flex-column justify-content-between">
                            <div className="t-s mb-lg-0 mb-5">
                                <h2 className="title-2 d-flex justify-content-center">Contact Us</h2>
                                <h1 className="title-1 d-flex justify-content-center">Get in Touch With Us</h1>
                                <p className="para-1 text-center">Every guide is trained and excited to work with you, whether you need help with a password reset or you're looking for a team to build your complete web presence.</p>
                            </div>
                            <div className="b-s">
                                <div className="row justify-content-center">
                                    <div className="col-auto mb-xl-0 mb-2 d-flex justify-content-center">
                                        <div className="box">
                                            <div className="icon">

                                                <FaHome className="lazy img-fluid" alt="icon" />
                                            </div>
                                            <p className="text">Head Office (Only inquiries)</p>
                                            <p className="text">24, St Albans, AL2 1TD,</p>
                                            <p className="text">England, United Kingdom</p>
                                            <a href="mailto:Info@techx.live" className="text">Info@techx.live</a>
                                        </div>
                                    </div>
                                    <div className="col-auto mb-lg-0 mb-2 d-flex justify-content-center">
                                        <div className="box">
                                            <div className="icon">
                                                <FaRegSun className="lazy img-fluid" alt="icon" />
                                            </div>
                                            <p className="text">Technical Support Assistance,</p>
                                            <p className="text">(Software or Hardware)[24/7]</p>
                                            <a href="mailto:support@techx.live" className="text">support@techx.live</a>

                                        </div>
                                    </div>
                                    <div className="col-auto mb-lg-0 mb-2 d-flex justify-content-center">
                                        <div className="box">
                                            <div className="icon">
                                                <FaPhoneSquareAlt  className="lazy img-fluid" alt="icon" />
                                            </div>
                                            <p className="text"> Fax Number,</p>
                                            {/* <p className="text">(Software or Hardware)[24/7]</p> */}
                                            <a href="mailto:support@techx.live" className="text"> +441923961688</a>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="faqs py-90" id="faqs">
                                <div className="container">

                                    <div className="space space-sm"></div>
                                    <div className="row">
                                        <div className="col-12 pr-3 mb-xl-0 mb-2">
                                            <div className="category row justify-content-center" style={{ gap: "10px" }} id="faqs-category">
                                                <div className={` col-sm-4 col-md-3 item ${activeFilter === 'ArtificialIntelligence' ? 'active' : ''}`} data-category="ArtificialIntelligence" onClick={() => handleDivClick('ArtificialIntelligence')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/artifical-intelligence.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Artificial Intelligence</h5>
                                                    </div>
                                                </div>
                                                <div className={` col-sm-4 col-md-3 item ${activeFilter === 'CyberSecurity' ? 'active' : ''}`} data-category="CyberSecurity" onClick={() => handleDivClick('CyberSecurity')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/cyber-security.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Cyber Security</h5>
                                                    </div>
                                                </div>
                                                <div className={` col-sm-4 col-md-3 item ${activeFilter === 'DataAnalytics' ? 'active' : ''}`} data-category="DataAnalytics" onClick={() => handleDivClick('DataAnalytics')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/cloud-service.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Data Analytics</h5>
                                                    </div>
                                                </div>
                                                <div className={` col-sm-4 col-md-3 item ${activeFilter === 'CloudServices' ? 'active' : ''}`} data-category="CloudServices" onClick={() => handleDivClick('CloudServices')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/cloud-hosting.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Cloud Services</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'ManagedIT' ? 'active' : ''}`} data-category="ManagedIT" onClick={() => handleDivClick('ManagedIT')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/technical-support.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Managed IT</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'VoIPSystem' ? 'active' : ''}`} data-category="VoIPSystem" onClick={() => handleDivClick('VoIPSystem')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/system.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">VoIP System</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'NetworkManagement' ? 'active' : ''}`} data-category="NetworkManagement" onClick={() => handleDivClick('NetworkManagement')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/network.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Network Management</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'HardwareSupport' ? 'active' : ''}`} data-category="HardwareSupport" onClick={() => handleDivClick('HardwareSupport')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/tools.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Hardware Support</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'MDM' ? 'active' : ''}`} data-category="MDM" onClick={() => handleDivClick('MDM')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/application.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">MDM (Mobile Device Management)</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'RemoteSupport' ? 'active' : ''}`} data-category="RemoteSupport" onClick={() => handleDivClick('RemoteSupport')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/teleworking.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Remote Support</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'ITConsultancy' ? 'active' : ''}`} data-category="ITConsultancy" onClick={() => handleDivClick('ITConsultancy')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/service.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">IT Consultancy</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'ITTraining' ? 'active' : ''}`} data-category="ITTraining" onClick={() => handleDivClick('ITTraining')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/technical-support.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">IT Training</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'ProjectManagement' ? 'active' : ''}`} data-category="ProjectManagement" onClick={() => handleDivClick('ProjectManagement')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/project.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Project Management</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'ITOutsourcing' ? 'active' : ''}`} data-category="ITOutsourcing" onClick={() => handleDivClick('ITOutsourcing')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/thin.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">IT Outsourcing</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'ITCompliance' ? 'active' : ''}`} data-category="ITCompliance" onClick={() => handleDivClick('ITCompliance')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/compliant.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">IT Compliance</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'UI/UXDesigning' ? 'active' : ''}`} data-category="UI/UXDesigning" onClick={() => handleDivClick('UI/UXDesigning')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/ui-ux.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">UI/UX Designing</h5>
                                                    </div>
                                                </div>
                                                <div className={`col-sm-4 col-md-3 item ${activeFilter === 'Emerging' ? 'active' : ''}`} data-category="Emerging" onClick={() => handleDivClick('Emerging')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/emergency-call.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">Emerging Tech Integration</h5>
                                                    </div>
                                                </div>
                                                <div className={`bi_height col-sm-4 col-md-3 item ${activeFilter === 'Analytics' ? 'active' : ''}`} data-category="Analytics" onClick={() => handleDivClick('Analytics')}>
                                                    <img
                                                        src="../../assets/images/icons/NavbarIcons/business.png"
                                                        className="icon img-fluid" alt="Icon" />
                                                    <div className="text">
                                                        <h5 className="item-title">BI & Analytics</h5>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className="content" ref={targetRef}>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'ArtificialIntelligence' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/artifical-intelligence.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Artificial Intelligence</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">What Artificial Intelligence (AI) services does TechX offer?</h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p> TechX provides a range of AI solutions, including machine learning, natural language processing, and predictive analytics, tailored to meet the diverse needs of businesses.</p>
                                                            </div>
                                                        </div>

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">How can AI benefit my business?</h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>AI can enhance efficiency, automate processes, and derive valuable insights from data, leading to improved decision-making and a competitive edge in your industry.</p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div className="box category-general" style={{ display: `${activeFilter === 'CyberSecurity' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/cyber-security.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Cyber Security</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">What Cyber Security services does TechX specialize in?</h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX offers comprehensive Cyber Security solutions, encompassing threat detection, risk assessments, and secure infrastructure to safeguard your digital assets.
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    How can TechX help protect my business from cyber threats?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    Our Cyber Security services involve proactive measures, such as firewall implementation, encryption, and continuous monitoring, to ensure the confidentiality and integrity of your data.
                                                                </p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'DataAnalytics' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/cloud-service.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Data Analytics</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What Data Analytics services does TechX provide?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX delivers advanced Data Analytics services, including data visualization, predictive modeling, and business intelligence, to help organizations extract meaningful insights from their data.                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    How can Data Analytics benefit my business operations?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    Data Analytics enables informed decision-making, identifies trends, and enhances overall business performance by leveraging the power of data.                                                                </p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'CloudServices' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/cloud-hosting.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Cloud Services</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What Cloud Services are offered by TechX?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX provides a comprehensive suite of Cloud Services, including cloud migration, infrastructure management, and scalable solutions hosted on leading cloud platforms.                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    How does migrating to the cloud benefit my business?                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    Cloud services offer increased flexibility, cost-effectiveness, and scalability, allowing businesses to adapt to changing demands efficiently.                                                                </p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'ManagedIT' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/technical-support.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Managed IT</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What is included in TechX's Managed IT services?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    Our Managed IT services cover proactive monitoring, software updates, network security, and technical support to ensure the smooth operation of your IT infrastructure.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can Managed IT services improve my business efficiency?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        Managed IT services prevent downtime, enhance security, and provide a reliable support system, allowing your team to focus on core business activities.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'VoIPSystem' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/system.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">VoIP System</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What VoIP System solutions does TechX offer?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX specializes in Voice over Internet Protocol (VoIP) solutions, providing cost-effective and feature-rich communication systems for businesses.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can a VoIP System benefit my organization?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        VoIP systems offer cost savings, flexibility, and advanced communication features, enabling efficient collaboration within your organization.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'NetworkManagement' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/network.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Network Management</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What does TechX's Network Management service entail?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX's Network Management service includes monitoring, optimization, and security measures to ensure the reliability and performance of your network infrastructure.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can Network Management contribute to my business success?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        Effective Network Management enhances connectivity, reduces downtime, and ensures a secure and stable network environment for your business operations.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'HardwareSupport' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/tools.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Hardware Support</h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What types of hardware support does TechX provide?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX offers comprehensive hardware support, covering troubleshooting, maintenance, and upgrades for various hardware components.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How does Hardware Support contribute to the longevity of my equipment?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        Regular hardware support ensures optimal performance, extends the lifespan of your equipment, and minimizes the risk of unexpected failures.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'MDM' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/application.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">MDM (Mobile Device Management)
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What is included in TechX's Mobile Device Management (MDM) service?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX's MDM service encompasses security, monitoring, and management of mobile devices to ensure data protection and efficient device usage.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can MDM benefit my business's mobile device fleet?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        MDM enhances security, streamlines device management, and facilitates efficient use of mobile devices within your organization.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'RemoteSupport' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/teleworking.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Remote Support
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What is Remote Support, and how does TechX provide it?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    Remote Support allows our experts to troubleshoot and resolve IT issues remotely, providing efficient and timely assistance to our clients.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How does Remote Support save time and enhance productivity?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        Remote Support enables quick issue resolution, minimizing downtime and allowing your team to remain focused on their tasks without interruption.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'ITConsultancy' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/service.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">IT Consultancy
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What IT Consultancy services does TechX offer?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX provides strategic IT consultancy, helping businesses align their IT infrastructure with organizational goals and navigate technological challenges.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can IT Consultancy benefit my business strategy?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        IT Consultancy offers expert guidance on technology adoption, risk management, and optimization of IT resources, contributing to a more effective business strategy.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="box category-general" style={{ display: `${activeFilter === 'ITTraining' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/technical-support.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">IT Training
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What IT Training programs are available at TechX?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX offers customized IT training programs covering a range of topics, ensuring that your team is equipped with the latest skills and knowledge.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can IT Training improve the skills of my team?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        IT Training enhances the proficiency of your team, keeping them up-to-date with technological advancements and improving overall productivity.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'ProjectManagement' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/project.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Project Management
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What Project Management services does TechX provide?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX's Project Management services include planning, execution, and monitoring of IT projects to ensure successful and timely completion.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can Project Management contribute to project success?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        Effective Project Management ensures efficient resource allocation, timely task completion, and overall success in meeting project goals.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'ITOutsourcing' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/thin.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">IT Outsourcing
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What IT Outsourcing solutions does TechX offer?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX provides IT Outsourcing services, allowing businesses to delegate specific IT tasks or functions to our experts for improved efficiency and cost-effectiveness.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can IT Outsourcing benefit my business operations?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        IT Outsourcing reduces operational burdens, provides access to specialized skills, and allows your team to focus on core business functions.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                             <div className="box category-general" style={{ display: `${activeFilter === 'Emerging' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/emergency-call.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">Emerging Tech Integration
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    How does TechX approach Emerging Tech Integration?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX facilitates the seamless integration of emerging technologies into existing systems, ensuring businesses stay ahead in the fast-paced technological landscape.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        Why is Emerging Tech Integration crucial for business innovation?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        Emerging Tech Integration fosters innovation, improves efficiency, and positions businesses to adapt to emerging trends in the ever-evolving tech landscape.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="box category-general" style={{ display: `${activeFilter === 'UI/UXDesigning' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/ui-ux.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">UI/UX Designing
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What UI/UX Designing services does TechX offer?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX specializes in UI/UX Designing, creating visually appealing and user-friendly interfaces to enhance the overall user experience.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can UI/UX Designing impact my digital presence?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        UI/UX Designing improves user engagement, navigation, and satisfaction, contributing to a positive online presence and brand perception.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box category-general" style={{ display: `${activeFilter === 'Analytics' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/business.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">BI & Analytics
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    What Business Intelligence (BI) and Analytics services are provided by TechX?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX offers BI and Analytics solutions, enabling businesses to leverage data for strategic decision-making and business growth.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        How can BI & Analytics benefit my business strategy?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        BI & Analytics provide actionable insights, identify trends, and support data-driven decision-making, leading to enhanced business performance.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                                                    <div className="box category-general" style={{ display: `${activeFilter === 'ITCompliance' ? 'block' : 'none'}` }}>
                                                    <div className="box-head">
                                                        <img
                                                            src="../../assets/images/icons/NavbarIcons/compliant.png"
                                                            className="icon img-fluid" alt="Icon" style={{ marginTop: "0px" }} />
                                                        <div className="text">
                                                            <h5 className="box-title">IT Compliance
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">

                                                        <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                            <div className="q-h">
                                                                <h4 className="text">
                                                                    How does TechX assist with IT Compliance?
                                                                </h4>
                                                                <div className="plus">
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="q-b">
                                                                <p>
                                                                    TechX supports businesses in achieving and maintaining IT compliance through assessments, policies, and implementation of necessary measures.
                                                                </p>
                                                            </div>

                                                            <div className="q" onClick={(e) => handleFAQClick(e)}>
                                                                <div className="q-h">
                                                                    <h4 className="text">
                                                                        Why is IT Compliance important for my business?
                                                                    </h4>
                                                                    <div className="plus">
                                                                        <span></span>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="q-b">
                                                                    <p>
                                                                        IT Compliance ensures adherence to industry regulations, protects sensitive data, and builds trust with clients and stakeholders.
                                                                    </p>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                               
















                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ThemeSetting />

                        <Cookie />


                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Contact);
