import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ThemeSetting from "./Include/ThemeSetting";
import Aos from "aos";
import "aos/dist/aos.css";
import Cookie from "./Include/Cookie";


const ServerManagment = () => {
    const baseUrl = `/assets/images`;
    const { pathname } = useLocation();
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <div className="container mt-2 mb-2">
                <div className="row">
                    <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center align-items-center">
                        <img
                            // src="/assets/images/icons/AI_Bannner_robot.png"
                            src={baseUrl + "/icons/server.png"}
                            className="banner-AI-img w-100"
                            alt=""
                        />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h2 className="AI-text-title-one">
                            Why Choose <span className="heading-text-AI-banner">TECHX</span> for Server Management?
                        </h2>
                        <p className="AI-text-title-three mt-2 text-justify" style={{ fontWeight: 500, fontSize: 20, lineHeight: 1.4 }}>
                            At TECHX, we redefine the landscape of IT training, transcending
                            conventional boundaries to cultivate a new era of expertise and
                            innovation. As an engineering company committed to pushing the
                            limits, we recognize the profound impact of continuous learning in
                            the dynamic field of technology
                        </p>
                    </div>
                </div>
            </div>
            <div class="se-iv-home py-90 bg-2">
                <div class="container">
                    <div class="se-head" style={{ margin: "0 auto 50px" }}>
                        <h3 class="se-title-1">Our Expertise</h3>
                    </div>
                    <div class="space space-sm"></div>
                    <div class="row text-center d-flex">
                        <div class="col-xl-4 col-lg-4 col-md-6 mb-2 d-flex">
                            <div class="box color-3 flex-grow-1">
                                <a  class="box-link"></a>
                                <h3 class="box-title">Professional Server Setup and Configuration</h3>
                                <p class="AI-text-title-three text-justify" style={{fontWeight:400}}>
                                    We provide expert setup and configuration services to ensure your servers are optimized for performance and security from the start. Our team handles everything, from initial installation to fine-tuning your server settings for maximum efficiency.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 mb-2 d-flex">
                            <div class="box color-3 flex-grow-1">
                                <a  class="box-link"></a>
                                <h3 class="box-title">Virtualization</h3>
                                <p class="AI-text-title-three text-justify" style={{fontWeight:400}}>
                                    Enhance your server's capabilities with our cutting-edge virtualization solutions. We help you maximize resource utilization, improve scalability, and reduce costs through efficient virtualization strategies tailored to your business needs.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 mb-2 d-flex">
                            <div class="box color-3 flex-grow-1">
                                <a  class="box-link"></a>
                                <h3 class="box-title">Database Management</h3>
                                <p class="AI-text-title-three text-justify" style={{fontWeight:400}}>
                                    Our database management services ensure your data is always secure, accessible, and efficiently managed. We handle everything from routine maintenance to complex database migrations, ensuring seamless operations and optimal performance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4 mb-2 voip-style3">
                <h2 className="text-center mb-2">Why We're the Best</h2>
                <div className="row voip-style4">
                    <div className="col-lg-4">
                        <h3>Experienced Professionals</h3>
                        <p data-aos="fade-down" className="text-left textColor text-justify" style={{ marginLeft: '15px' }}> Our team comprises seasoned experts with extensive experience in server management and IT infrastructure. We stay up-to-date with the latest industry trends and technologies to provide you with top-notch service.
                        </p>
                    </div>
                    <div className="col-lg-4">
                        <h3>Customized Solutions</h3>
                        <p data-aos="fade-down" className="text-left textColor text-justify" style={{ marginLeft: '15px' }}>We understand that every business is unique. That's why we offer customized server management solutions tailored to your specific requirements, ensuring you get exactly what you need.</p>
                    </div>
                    <div className="col-lg-4">
                        <h3>24/7 Support</h3>
                        <p data-aos="fade-down" className="text-left textColor text-justify" style={{ marginLeft: '15px' }}>Our dedicated support team is available around the clock to assist you with any server-related issues. Whether it's routine maintenance or emergency troubleshooting, we're here to help.</p>
                    </div>
                    <div className="col-lg-4 mt-2">
                        <h3>Enhanced Features</h3>
                        <p data-aos="fade-down" className="text-left textColor text-justify" style={{ marginLeft: '15px' }}> Experience VoIP like never before with TECHX. Our systems come
                            equipped with advanced features such as crystal-clear HD voice quality, intuitive user
                            interfaces, and robust security protocols, ensuring a superior communication experience.</p>
                    </div>
                    <div className="col-lg-4 mt-2">
                        <h3>Proactive Monitoring and Maintenance</h3>
                        <p data-aos="fade-down" className="text-left textColor text-justify" style={{ marginLeft: '15px' }}>We employ proactive monitoring tools and strategies to identify and address potential issues before they become critical problems. Our approach ensures minimal downtime and uninterrupted service.</p>
                    </div>
                    <div className="col-lg-4 mt-2">
                        <h3>Security and Compliance</h3>
                        <p data-aos="fade-down" className="text-left textColor text-justify" style={{ marginLeft: '15px' }}>Security is our top priority. We implement robust security measures to protect your servers from threats and ensure compliance with industry standards and regulations.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-4 mb-2 voip-style3 ">
                <h2 className="text-center mb-2">Join TECHX Today</h2>
                <p className="mt-1 textColor text-justify" data-aos="fade-down" style={{ fontSize: 20 }}>Partner with TECHX for your server management needs and experience unparalleled service and support. Let us handle the complexities of server management so you can focus on what you do best – growing your business.
                </p>
            </div>

            <div className="container" style={{ paddingTop: 40 }}>
                <div className="voip-head4d" style={{ marginTop: 5 }}>
                    <p className="pa4" data-aos="fade-down" style={{ fontSize: 25 }}>
                        <span>Contact us today</span> to learn more about our server management services and how we can help your business thrive.
                    </p>
                </div>
            </div>
            <Cookie/>
            <ThemeSetting />
        </>
    )
}


export default ServerManagment