
import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const EnterpriseHardware = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(/assets/images/pages/EnterpriseHardware/slide1.webp)`,
                    minHeight: '100%'
                }}
            >
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-8">
                            <h3
                                className="AI-text-title-one white-theme-text-AI color-about " data-aos="fade-up"
                            >
                                Enterprise Hardware
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <p className="f_22 textColor text-justify" data-aos="fade-down">At  <span className="heading-text-AI-banner " style={{ fontSize: 22 }}>TechX</span>, we provide top-of-the-line Enterprise Hardware solutions tailored to meet the demands of large-scale business operations. Whether you need powerful servers for data management or high-performance computing for intensive workloads, our enterprise hardware offerings are designed to ensure reliability, scalability, and superior performance.</p>
            </div>
            <hr style={{ color: '#e2e9ee' }} />
            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Enterprise Servers</h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Our enterprise servers are built to handle the most demanding workloads, offering unparalleled reliability, scalability, and security for your business.   </p>
                <div className="row text-center">
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-3">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/EnterpriseHardware/available.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h3 className="box-title">High Availability</h3>
                            <p className="box-para text-justify textColor" data-aos="fade-down">
                                Ensure continuous operation with fault-tolerant designs and redundancy. Multiple power supplies keep your servers operational even if one fails, while automatic failover mechanisms seamlessly switch to a standby server in case of hardware failure. Workloads are distributed across multiple servers to prevent any single server from becoming a point of failure.  </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-5">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/EnterpriseHardware/scalability.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Scalability</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Easily expand your server capacity as your business grows. The modular design allows you to add or remove components like processors and storage as needed, ensuring flexibility. Whether through horizontal scaling by adding more servers or vertical scaling by upgrading existing server capabilities, your infrastructure can adapt to increased demands. </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/EnterpriseHardware/security.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Advanced Security</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Protect your enterprise with integrated security features that guard against cyber threats. Hardware-level encryption secures sensitive data directly within the server, while real-time intrusion detection systems monitor and prevent unauthorized access. Secure boot technology ensures that only trusted software is loaded during server startup.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/EnterpriseHardware/custom.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title"> Custom Configuration</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Tailor your server solutions to meet your unique business requirements. From custom hardware selection to specialized software integration, our servers are configured to optimize your operations. Whether you need a specific network design for low latency or high bandwidth, or a purpose-built system for applications like databases or AI processing, we have you covered.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/EnterpriseHardware/management.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Efficient Resource Management</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Optimize server performance through intelligent resource allocation. Dynamic resource management ensures that CPU, memory, and storage are allocated based on real-time workload demands. Resource monitoring tools provide insights into usage, helping to identify inefficiencies and optimize performance. Power management features for reduce energy consumption.Regular capacity optimization reviews ensure your servers operate at peak efficiency.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/HardwareMaintenance/support.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">24/7 Support</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                Keep your servers running smoothly with dedicated 24/7 support. Continuous monitoring of server health and performance ensures that potential issues are detected early, while proactive maintenance helps prevent problems before they occur. Our expert technicians are available around the clock to provide quick and efficient technical assistance, whether through remote troubleshooting or on-site support for complex issues.</p>
                        </div>
                    </div>
                </div>
            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">High-Performance Computing</h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Leverage our high-performance computing solutions to tackle complex computational tasks and large-scale data processing with ease.
                </p>
                <div className="row">
                    <div className="col-lg-6 d-flex  " data-aos="fade-zoom-in">
                        <img src="assets/images/pages/EnterpriseHardware/high.jpg" className="w-100" data-aos="fade-zoom-in" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex  flex-column justify-content-center py-lg-2 align-items-center" style={{ gap: 10 }}>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Parallel Processing Power</strong> : Accelerate your operations with multi-core and parallel processing capabilities.                        </p>
                        <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Optimized for Big Data</strong> : Handle massive datasets with powerful computing resources.</p>
                        <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Energy Efficiency</strong> : Reduce power consumption while maintaining top performance.</p>
                        <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Customizable Clusters</strong> : Build HPC clusters tailored to your specific computational needs.</p>
                        <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Fast Data Access</strong> : High-speed data storage and retrieval for real-time processing.</p>
                        <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Advanced Cooling Solutions</strong> : Maintain optimal performance with state-of-the-art cooling systems.</p>
                    </div>
                </div>
            </div>

            <div className="container pt-lg-3 mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Additional Enterprise Hardware Solutions
                </h3>


                <div className="row nm-style4 mt-sm-2 mt-1 py-lg-2 py-md-1">
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1 ">
                        <h3 className="textColor">Data Storage Solutions
                        </h3>
                        <p data-aos="fade-rown">Our data storage solutions are designed to meet the growing demands of modern enterprises. With high-capacity storage arrays, you can store vast amounts of data securely and efficiently. We offer scalable storage options that allow your storage capacity to grow with your business. Secure data encryption ensures that sensitive information is protected at all times. </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Advanced Networking Equipment</h3>
                        <p data-aos="fade-rown">We provide enterprise-grade networking equipment that forms the backbone of your IT infrastructure. Our high-performance routers and switches ensure reliable and fast data transmission across your network. Secure firewalls and VPNs protect your network from unauthorized access, ensuring data integrity and security.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Virtualization Solutions</h3>
                        <p data-aos="fade-rown">Our virtualization solutions enable you to maximize the efficiency of your IT resources. Server virtualization allows you to run multiple virtual servers on a single physical machine, optimizing resource use and reducing costs. Desktop virtualization provides remote access to desktop environments, enabling employees to work from anywhere with ease. </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Secure Backup & Recovery Solutions</h3>
                        <p data-aos="fade-rown">Protect your business from data loss with our comprehensive backup and recovery solutions. We offer automated backup systems that ensure your data is regularly backed up without manual intervention. Our solutions include secure off-site storage, keeping your backups safe from physical damage or theft. </p>
                    </div>
                



                </div>

            </div>


            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default EnterpriseHardware