import { Link } from "react-router-dom/cjs/react-router-dom.min"
import ThemeSetting from "./Include/ThemeSetting"


const PrivacyPolicy = () => {
    return (
        <>
            <div className="se-i-support py-120">
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-start">Privacy Policy</h1>
                        <p className="para-1 text-start">At TechX, we respect your privacy and are committed to protecting your personal data. This privacy policy outlines how we handle your personal information, your privacy rights, and how the law protects you.</p>
                    </div>

                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-start" style={{ fontSize: '28px', marginTop: '45px' }}>Information We Collect</h1>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Personal Identification Information : </strong>Name, email address, phone number, etc.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Technical Data : </strong>IP address, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Usage Data : </strong>Information about how you use our website, products, and services.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Marketing and Communications Data : </strong>Your preferences in receiving marketing from us and your communication preferences.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-start" style={{ fontSize: '28px', marginTop: '45px' }}>How We Use Your Information</h1>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>To Provide Services : </strong> We use your personal data to deliver our services, process transactions, and manage your account.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>To Improve Our Services : </strong> We analyze your usage data to understand how you interact with our website and services, helping us improve our offerings.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>To Communicate with You : </strong> We use your contact information to send important updates, promotional materials, and respond to your inquiries.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>To Ensure Security : </strong> We utilize technical data to maintain the security of our website and prevent fraud.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Legal Compliance : </strong> - We process your personal data as required to comply with legal obligations.
                        </p>
                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-start" style={{ fontSize: '28px', marginTop: '45px' }}>Data Sharing and Disclosure</h1>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Third-Party Service Providers : </strong>We share your information with third-party service providers who assist us in delivering our services, such as payment processors and IT service providers.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Legal Requirements : </strong> We may disclose your information if required by law or in response to valid requests by public authorities.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-start" style={{ fontSize: '28px', marginTop: '45px' }}>Your Data Protection Rights</h1>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Access : </strong>You have the right to request copies of your personal data.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Rectification : </strong> You have the right to request correction of inaccurate or incomplete data.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Erasure : </strong> You have the right to request deletion of your personal data under certain conditions.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Restrict Processing : </strong> You have the right to request the restriction of processing your personal data under certain conditions.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Data Portability : </strong> You have the right to request the transfer of your data to another organization or directly to you under certain conditions.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Object to Processing : </strong> You have the right to object to our processing of your personal data under certain conditions.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-center" style={{ fontSize: '28px', marginTop: '45px' }}>Contat Us</h1>
                        <p className="para-1 text-center">For any questions or concerns regarding this privacy policy or our data practices, please contact us at <a  style={{textDecoration: 'none', borderBottom: '1px solid #999999' , color:"#1344ff"}}>privacy@techx.live</a>
                        </p>

                    </div>
                </div>
            </div>

            <ThemeSetting />
        </>
    )
}
export default PrivacyPolicy