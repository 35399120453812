/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import FAQ from "../../Components/FAQ";
import VPSPlans from "../../Components/VPSPlans";
import CallingMethod from "../../Services/CallingMethod";
import { capitalizeFirst, readLess } from "../../Services/Custom";
import Cookie from "./Include/Cookie";
import Header from "./Include/Header";
import ThemeSetting from "./Include/ThemeSetting";
import { BsShieldCheck, BsTrophy } from "react-icons/bs";
import { MdSupportAgent, MdReviews } from "react-icons/md";
import "./styles/Index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImQuotesRight } from "react-icons/im";
import { RiSecurePaymentLine } from "react-icons/ri";
import Ai1 from '../Website/styles/Images/ai1.jpg'
import Ai2 from '../Website/styles/Images/ai2.jpg'
import Ai3 from '../Website/styles/Images/ai3.jpg'
import img1 from '../Website/styles/Images/photo1711292440.jpeg'
import img2 from '../Website/styles/Images/2.jpeg'
import img5 from '../Website/styles/Images/3.jpeg'
import handshake from '../Website/styles/Images/handshake.png'
import img3 from '../Website/styles/Images/photo1711292467.jpeg'
import img4 from '../Website/styles/Images/photo1711292478.jpeg'
// import { Link, withRouter, useHistory } from "react-router-dom";
// import useLoader from "../Services/useLoader";
export const Home = (props) => {


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    className: "myCustomCarousel",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  const baseUrl = location.href.includes("trainingncr.info")
    ? `http://trainingncr.info/techx/assets/images`
    : `/assets/images`;
  const [dataGet, setDataGet] = useState("");
  function parentAlert(data) {
    setDataGet(data);
  }
  return (
    <>
      <Header />


      <div className="se-ii-home bg-2">
        <div className="container">
          {/* <!-- row --> */}
          <div className="row align-items-center">
            {/* <!-- col --> */}
            <div className="col-xl-4 col-12 text-xl-left text-center mb-xl-0 mb-5">
              {/* <!-- title-1 --> */}
              <h2 className="title-1 mb-1">
                <span>Popular</span><br className="d-xl-block d-none" />
                <span>operating</span><br className="d-xl-block d-none" />
                <span>systems.</span><br />
                <span className="primary-color">Your Choice.</span>
              </h2>
              {/* <!-- para-1 --> */}
              <p className="para-1 mb-0">With ability to upload your own Operating system.</p>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-8 col-12">
              {/* <!-- row --> */}
              <div className="row justify-content-center">
                {/* <!-- col --> */}
                <div className="col-lg-2 col-md-3 col-6" style={{ padding: '5px' }}>
                  {/* <!-- item --> */}
                  <div className="item text-center operating-box" >
                    <img
                      // src="/assets/images/os/windows.png" 
                      src={baseUrl + "/os/windows.png"}
                      className="lazy img-fluid" alt="Windows" />
                    <h3 className="text mb-0">Windows</h3>
                  </div>
                </div>
                {/* <!-- col --> */}
                <div className="col-lg-2 col-md-3 col-6" style={{ padding: '5px' }}>
                  {/* <!-- item --> */}
                  <div className="item text-center operating-box">
                    <img
                      // src="/assets/images/os/android.png"
                      src={baseUrl + "/os/android.png"}
                      className="lazy img-fluid" alt="Android" />
                    <h3 className="text mb-0">Android</h3>
                  </div>
                </div>
                {/* <!-- col --> */}
                <div className="col-lg-2 col-md-3 col-6" style={{ padding: '5px' }}>
                  {/* <!-- item --> */}
                  <div className="item text-center operating-box">
                    <img
                      // src="/assets/images/os/ubuntu.png" 
                      src={baseUrl + "/os/ubuntu.png"}
                      className="lazy img-fluid" alt="Ubuntu" />
                    <h3 className="text mb-0">Ubuntu</h3>
                  </div>
                </div>
                {/* <!-- col --> */}
                <div className="col-lg-2 col-md-3 col-6" style={{ padding: '5px' }}>
                  {/* <!-- item --> */}
                  <div className="item text-center operating-box">
                    <img
                      // src="/assets/images/os/debian.png"
                      src={baseUrl + "/os/debian.png"}
                      className="lazy img-fluid" alt="Debian" />
                    <h3 className="text mb-0">Debian</h3>
                  </div>
                </div>
                {/* <!-- col --> */}
                <div className="col-lg-2 col-md-3 col-6" style={{ padding: '5px' }}>
                  {/* <!-- item --> */}
                  <div className="item text-center operating-box">
                    <img
                      //  src="/assets/images/os/centos.png"
                      src={baseUrl + "/os/centos.png"}
                      className="lazy img-fluid" alt="CentOS" />
                    <h3 className="text mb-0">CentOS</h3>
                  </div>
                </div>
                {/* <!-- col --> */}
                <div className="col-lg-2 col-md-3 col-6" style={{ padding: '5px' }}>
                  {/* <!-- item --> */}
                  <div className="item text-center operating-box">
                    <img
                      //  src="/assets/images/os/open-suse.png"
                      src={baseUrl + "/os/open-suse.png"}
                      className="lazy img-fluid" alt="Open Suse" />
                    <h3 className="text mb-0">Open Suse</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="se-i-home text-lg-left text-center pt-5">
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- col --> */}
            <div className="col-lg-3 col-6 mb-lg-0 mb-2">
              {/* <!-- box --> */}
              <div className="box">
                <img src="/assets/images/icons/mail.svg" className="lazy box-icon" alt="Mail" />
                <h2 className="box-title mb-0">Anonymous<br />Access</h2>
              </div>
            </div>

            {/* <!-- col --> */}
            <div className="col-lg-3 col-6 mb-lg-0 mb-2">
              {/* <!-- box --> */}
              <div className="box">
                <img src="/assets/images/icons/logout.svg" className="lazy box-icon" alt="Logout" />
                <h2 className="box-title mb-0">No Usage<br />Logs</h2>
              </div>
            </div>

            {/* <!-- col --> */}
            <div className="col-lg-3 col-6">
              {/* <!-- box --> */}
              <div className="box">
                <img src="/assets/images/icons/digital.svg" className="lazy box-icon" alt="Digital" />
                <h2 className="box-title mb-0">No Usage<br />Limit</h2>
              </div>
            </div>

            {/* <!-- col --> */}
            <div className="col-lg-3 col-6">
              {/* <!-- box --> */}
              <div className="box">
                <img src="/assets/images/icons/wishlist.svg" className="lazy box-icon" alt="Wishlist" />
                <h2 className="box-title mb-0">100% White . .<br />Listed</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="elementor-all-section">
        <div className="overlay-elementor-section"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-2">
              <div className="box-elementor-text">
                <h6 className="box-title-text-elementor text-lg-left text-center" style={{ color: "#2cd97b", fontWeight: 500, fontSize: 24 }}>WHY CHOOSE US</h6>
                <h2>See the difference TechX can do for you!</h2>
                <p className="box-des-text">
                  Our state-of-the-art servers ensure maximum uptime, providing
                  a reliable hosting environment for your website. With robust
                  security measures in place, you can trust that your data and
                  sensitive information are protected.
                </p>
              </div>
            </div>
            <div className="col-lg-7 mb-2">
              <div className="elementor-box-section">
                <div className="box-content-element">
                  <div className="elementor-box one-box">
                    {/* <BsShieldCheck className="icon" /> */}
                    <RiSecurePaymentLine className="icon" />
                    <h3>Be Secure</h3>
                    <p className="card-box-des">
                      We safeguard your online presence from hackers and
                      spammers by using advanced tools.
                    </p>
                  </div>
                  <div className="elementor-box second-box">
                    <MdSupportAgent className="icon" />
                    <h3>24/7 Support</h3>
                    <p className="card-box-des">
                      We believe that exceptional support is crucial for a
                      seamless and stress-free hosting experience.
                    </p>
                  </div>
                </div>
                <div className="box-content-element third-box">
                  <div className="elementor-box  ">
                    <MdReviews className="icon review-icon" />
                    <h3>Good Review</h3>
                    <p className="card-box-des">
                      We are the hosting provider you can rely on for
                      exceptional service, unmatched performance, and peace of
                      mind.
                    </p>
                  </div>
                  <div className="elementor-box fourth-box">
                    <BsTrophy className="icon" />
                    <h3>Prestigious</h3>
                    <p className="card-box-des">
                      TechX is a prestigious company where excellence meets
                      technology. We take pride in offering hosting services
                      that are synonymous with prestige and quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container mt-5">
        <div className="se-head mt-2" style={{ margin: "0 auto 55px" }}>
          <h3 className="se-title-1">Hardware Support </h3>
        </div>
        <div className="row">
          <div className="container-fluid  webhosting-all-section  mt-lg-4 mb-lg-4 mt-sm-3 mb-sm-3 mt-2 mb-2">
            <div className="row ">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="sec-title-It mt-1" data-aos="fade-left">
                    AI-Driven Hardware Innovations:
                  </h1>
                  <br />
                  <p className="sec-para-It mb-1" data-aos="fade-left">
                    <ul>
                      <li>AI accelerators and specialized hardware for deep learning and neural network processing.</li>
                      <li>Edge computing devices equipped with AI algorithms for real-time data analysis and decision-making.</li>
                      <li>Autonomous hardware platforms for robotics, autonomous vehicles, and industrial automation.</li>
                      <li>Neuromorphic computing architectures inspired by the human brain for efficient and intelligent computing.</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center home_img_padding">
                <img
                  src={img1}
                  className=" img-fluid w-100"
                  alt="Image"
                />
              </div>
            </div>
          </div>
          <div className="container-fluid pt-2 webhosting-all-section  mt-lg-4 mb-lg-4 mt-sm-3 mb-sm-3 mt-2 mb-2">

            <div className="row ">
              <div className="col-lg-6 d-flex justify-content-center align-items-center home_img_padding">
                <img
                  src={img5}
                  className=" img-fluid w-100"
                  alt="Image"
                />
              </div>
              <div className="col-lg-6">
                <div className="">
                  <h1 className="sec-title-It mt-1" data-aos="fade-left">
                    Cutting-edge Electronics and Embedded Systems:
                  </h1>
                  <br />
                  <p className="sec-para-It mb-1" data-aos="fade-left">
                    <ul>
                      <li>Custom electronic components and modules tailored for specific applications and industries.</li>
                      <li>Embedded systems and IoT devices for smart cities, smart agriculture, and environmental monitoring.</li>
                      <li>Wearable technology solutions for healthcare, fitness, and augmented reality applications.</li>
                      <li>High-performance computing solutions for scientific research, data analysis, and simulations.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-2 webhosting-all-section  mt-lg-4 mb-lg-4 mt-sm-3 mb-sm-3 mt-2 mb-2">

            <div className="row ">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="sec-title-It mt-1" data-aos="fade-left">
                    Innovative Hardware for Emerging Technologies:
                  </h1>
                  <br />
                  <p className="sec-para-It mb-1" data-aos="fade-left">
                    <ul>
                      <li>Quantum computing hardware for solving complex problems in cryptography, optimization, and scientific research.</li>
                      <li>Biometric hardware solutions for identity verification, access control, and security applications.</li>
                      <li>Advanced sensor technologies for environmental monitoring, healthcare diagnostics, and smart infrastructure.</li>
                      <li>Robotics hardware platforms for industrial automation, healthcare assistance, and educational purposes.</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center home_img_padding">
                <img
                  src={img2}
                  className=" img-fluid w-100"
                  alt="Image"
                />
              </div>
            </div>
            <Link to="/HardwareSupport"
              className="AI-text-title-one-btn mt-lg-3" style={{ margin: 'auto' }}
            >
              Know More
            </Link>
          </div>
        </div>
        <div className="se-head ">

        </div>
      </div>
      <div className="se-head mt-2" style={{ margin: "0 auto 55px" }}>
        <h3 className="se-title-1">AI is our future </h3>
      </div>
      <div className="container">

        <div className="row mb-2">
          <div className="col-4"><img src={Ai1} className="w-100 h-100" style={{ borderRadius: "15px" }} /></div>
          <div className="col-4"><img src={Ai2} className="w-100 h-100" style={{ borderRadius: "15px" }} /></div>
          <div className="col-4"><img src={Ai3} className="w-100 h-100" style={{ borderRadius: "15px" }} /></div>
        </div>
      </div>


      {/* <!-- Section IV --> */}
      <div className="se-iv-home  bg-2">
        <div className="container ">
          {/* <!-- se-head --> */}
          <div className="se-head ">
            <h3 className="pt-lg-5 pt-3 se-title-1">MORE THAN JUST A HOSTING COMPANY.</h3>
            <h4 className="se-title-2">
              Best services you get from TechX Ltd.
            </h4>
          </div>
          {/* <!-- space --> */}
          <div className="space space-sm"></div>
          {/* <!-- row --> */}
          <div className="row justify-content-center">
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              {/* <!-- box --> */}
              <div className="box color-1">
                <a href="UiDesigning" target="_blank" className="box-link"></a>
                {/* <!-- link --> */}

                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/businessman.svg"
                    src={baseUrl + "/icons/businessman.svg"}
                    className="lazy img-fluid"
                    alt="businessman"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  UI/UX
                  <br />
                  Design
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">
                  UI/UX design enhances digital interactions, combining visual appeal (UI) with user satisfaction (UX) for a seamless and engaging experience.
                  <br />
                  Thankfully.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"

                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              {/* <!-- box --> */}
              <div className="box color-2">
                <a href="/ArtificialIntelligence" target="_blank" className="box-link"></a>
                {/* <!-- link --> */}

                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/priority.svg"
                    src={baseUrl + "/icons/priority.svg"}
                    className="lazy img-fluid"
                    alt="priority"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  Artificial
                  <br />
                  Intelligence
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">
                  Artificial intelligence (AI) empowers companies with data-driven insights, automation, and efficiency, driving innovation, and informed decision-making.
                  <br />
                  Thankfully.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"
                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              {/* <!-- box --> */}
              <div className="box color-3">
                <a href="/ITConsultancy" target="_blank" className="box-link"></a>
                {/* <!-- link --> */}

                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/mission.svg"
                    src={baseUrl + "/icons/mission.svg"}
                    className="lazy img-fluid"
                    alt="mission"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  IT
                  <br />
                  Consultancy
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">

                  IT consultancy guides companies in leveraging technology to optimize operations, enhance efficiency, and achieve strategic goals, offering expert advice for effective digital solutions.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"
                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              {/* <!-- box --> */}
              <div className="box color-4">
                {/* <!-- link --> */}
                <a href="/cyber-security" target="_blank" className="box-link"></a>
                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/hula-hoop.svg"
                    src={baseUrl + "/icons/hula-hoop.svg"}
                    className="lazy img-fluid"
                    alt="hula-hoop"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  Cyber <br />
                  Security
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">
                  In today's interconnected world, where our lives and businesses are heavily reliant on digital technology, protecting ourselves from cyber threats has become paramount.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"
                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              {/* <!-- box --> */}
              <div className="box color-5">
                <a href="/ItTraining" target="_blank" className="box-link"></a>
                {/* <!-- link --> */}

                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/data-storage-device.svg"
                    src={baseUrl + "/icons/data-storage-device.svg"}
                    className="lazy img-fluid"
                    alt="data-storage-device"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  IT
                  <br />
                  Training
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">

                  IT training equips company personnel with essential skills and knowledge, fostering a tech-savvy workforce that adapts to evolving technologies, boosting productivity and innovation.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"
                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
              {/* <!-- box --> */}
              <div className="box color-6">
                {/* <!-- link --> */}
                <a href="/CloudService" target="_blank" className="box-link"></a>
                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/power.svg"
                    src={baseUrl + "/icons/power.svg"}
                    className="lazy img-fluid"
                    alt="power"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  Cloud
                  <br />
                  Service
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">
                  Cloud services have revolutionized the way businesses operate by offering flexible, scalable, and cost-effective solutions for various computing needs.
                  <br />
                  Thankfully.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"
                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-2">
              {/* <!-- box --> */}
              <div className="box color-1">
                <a href="/Projectmanagement" target="_blank" className="box-link"></a>
                {/* <!-- link --> */}

                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/network.svg"
                    src={baseUrl + "/icons/network.svg"}
                    className="lazy img-fluid"
                    alt="network"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  Project                  <br />
                  Management
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">
                  Project management ensures efficient planning, execution, and completion of tasks within a company. It involves organizing resources, timelines, and teams to achieve specific goals, enhancing overall effectiveness and success.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"
                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
            {/* <!-- col --> */}
            <div className="col-xl-3 col-lg-4 col-md-6">
              {/* <!-- box --> */}
              <div className="box color-2">
                <Link to="/NetworkManagement" className="box-link"></Link>
                {/* <!-- link --> */}

                {/* <!-- icon --> */}
                <div className="icon">
                  <img
                    // src="/assets/images/icons/security-on.svg"
                    src={baseUrl + "/icons/security-on.svg"}
                    className="lazy img-fluid"
                    alt="security-on"
                  />
                </div>
                {/* <!-- box-title --> */}
                <h4 className="box-title">
                  Network
                  <br />
                  Management
                </h4>
                {/* <!-- box-para --> */}
                <p className="box-para">

                  Network management for a company involves overseeing and optimizing the organization's computer networks. It includes monitoring performance, ensuring security, and maintaining seamless connectivity to support efficient business operations.
                </p>
                {/* <!-- arrow --> */}
                <div className="arrow text-right">

                  <img
                    // src="/assets/images/icons/right-arrow.svg"
                    src={baseUrl + "/icons/right-arrow.svg"}
                    className="lazy img-fluid"
                    alt="Right-Arrow"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-lg-5 mt-3 py-lg-1 py-3">

          <div className="row">
            <div className="col-lg-6 px-sm-5 px-lg-0"><img src={handshake} className="w-100" /></div>
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <div className="se-head" style={{ maxWidth: "100%" }}>
                <h3 className="se-title-1">Your On-Demand IT Solutions – No Contracts, Just Results</h3>
              </div>
              <div className="textColor" style={{ fontSize: 22, fontWeight: 500, textAlign: 'justify' }}>
                At Techx, we understand the hassle of long-term contracts. That's why we offer flexible, day-to-day IT support tailored to your business needs. Our expert team is ready to tackle your software and IT challenges with precision and speed, ensuring your operations run smoothly without the need for commitment. Trust Techx to be your go-to IT partner, delivering quick, reliable solutions when you need them most.
              </div>
              <div className="mt-lg-3 mb-lg-2 mt-2 mb-1" >
                <Link to="/contact"
                className="home_know"
                >
                  
                  Know More
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Section V --> */}
      {/* <VPSPlans/> */}

      {/* <!-- Section VI --> */}
      <div className="se-vi-home py-90 bg-2">
        <div className="container">
          {/* <!-- se-head --> */}
          <div className="se-head">
            <h3 className="se-title-1">Trust our customers</h3>
            <h4 className="se-title-2">
              VPS Hosting What Our Customers Have To Say?
            </h4>
          </div>
          {/* <!-- space --> */}
          <div className="space space-sm"></div>

          <div className="slider-home-page-section">
            <Slider {...settings}>
              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/01.jpg"
                      src={baseUrl + "/icons/hosting-6.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Reliable Service
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Instagram CEO
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    The IT company consistently delivers reliable and efficient solutions.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>


              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/01.jpg"
                      src={baseUrl + "/icons/hosting-7.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Streamlined Operations
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Instagram CEO
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Their IT solutions significantly improved our business processes.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>


              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/01.jpg"
                      src={baseUrl + "/icons/hosting-16.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Security and Compliance
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Instagram CEO
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Maintains a strong focus on data security and compliance.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>


              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/01.jpg"
                      src={baseUrl + "/icons/hosting-12.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Timely Project Completion
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Instagram CEO
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Consistently delivers projects on time, meeting our deadlines.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>



              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/01.jpg"
                      src={baseUrl + "/icons/hosting-10.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Long-Term Partnerships
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Instagram CEO
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Established a strong and lasting partnership, adding value to our organization.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>




              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/02.jpg"
                      src={baseUrl + "/icons/hosting-11.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Excellent Customer Support
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Adobe Product Manager
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Their customer support team is incredibly responsive and helpful.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>
              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/03.jpg"
                      src={baseUrl + "/icons/hosting-14.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Skilled Technicians
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Full Stack Web Developer
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Highly skilled IT professionals who tackle any problem swiftly.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>
              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/04.jpg"
                      src={baseUrl + "/icons/hosting-17.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Innovative Solutions
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Graphic Designer
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Always at the forefront of technology, offering innovative solutions.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>
              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/05.jpg"
                      src={baseUrl + "/icons/hosting-8.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Cost-Effective
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Adobe Product Manager
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Providing top-notch services at a competitive price.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>
              <div>
                <div className="slider-home-box">
                  <div className="home-box-image-section ">
                    <img
                      // src="/assets/images/avatars/01.jpg"
                      src={baseUrl + "/icons/hosting-13.png"}
                      className="slider-img-home img-fluid"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title-home-text text-center mt-1">
                    Customization and Flexibility
                  </div>
                  {/* <div className="title-home-text text-center mt-1">
                    Full Stack Web Developer
                  </div> */}
                  <p className="quotes-slider-text mt-1">
                    Tailored solutions to meet our unique business needs.
                  </p>
                  <ImQuotesRight className="icon-slider-home mt-2" />
                </div>
              </div>
            </Slider>
          </div>
          <div className="space space-sm"></div>
        </div>
      </div>

      <div className="se-head mt-2 mb-5">
        <h3 className="se-title-1">FAQS</h3>
        <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
        <Link to="/support"
          className="AI-text-title-one-btn" style={{ margin: 'auto' }}
        >
          Support
        </Link>
      </div>

      {/* <FAQ /> */}

      <ThemeSetting dataAlert={parentAlert} />

      <Cookie />
    </>
  );
};

export default withRouter(Home);
