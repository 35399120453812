import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const HardwareMaintenance = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(/assets/images/pages/HardwareMaintenance/banner.jpg)`,
                    minHeight: '100%'
                }}
            >
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-8">
                            <h3
                                className="AI-text-title-one white-theme-text-AI color-about " data-aos="fade-up"
                            >
                                Comprehensive Hardware Maintenance Solutions
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <p className="f_22 textColor text-justify" data-aos="fade-down">At  <span className="heading-text-AI-banner " style={{ fontSize: 22 }}>TechX</span>, we understand the importance of keeping your technology infrastructure running at its best. Our comprehensive hardware maintenance services are designed to prevent issues before they arise and ensure your systems are always up-to-date. From preventive maintenance to upgrade services and more, we’ve got you covered. </p>
            </div>
            <hr style={{ color: '#e2e9ee' }} />
            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Preventive Maintenance</h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Preventive maintenance is the cornerstone of a reliable IT infrastructure. At TechX, we take a proactive approach to maintaining your hardware, ensuring that potential issues are identified and resolved before they can impact your business operations.
                </p>
                <div className="row text-center">
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-3">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/HardwareMaintenance/RegularInspection.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h3 className="box-title">Regular Inspections</h3>
                            <p className="box-para text-justify textColor" data-aos="fade-down">
                                We perform routine checks on all hardware components, including servers, workstations, and networking equipment, to ensure they are operating efficiently. Our inspections are not just surface-level; we delve deep into each component, checking for any signs of wear and tear, loose connections, or overheating issues. By ensuring that your hardware environment is optimized, we prevent small issues from escalating into significant problems.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-5">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/icons/It2.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Performance Monitoring</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                Continuous monitoring of system performance helps us detect and address potential bottlenecks before they cause downtime. We track key performance indicators such as CPU usage, memory allocation, and network traffic in real-time, providing an ongoing assessment of your system’s health. Our monitoring tools are configured to trigger alerts at the first sign of irregularity, allowing us to take immediate action.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/HardwareMaintenance/PerformanceMonitoring.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Firmware & Software Updates</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                We keep your systems up-to-date with the latest firmware and software patches, enhancing security and performance. Our update process is carefully managed to minimize disruption, with updates scheduled during low-traffic periods. Before implementation, we rigorously test each update to ensure it is compatible with your existing hardware and software.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/HardwareMaintenance/Cleaning.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title"> Cleaning & Recalibration</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                Regular cleaning and recalibration of sensitive equipment ensure longevity and optimal functioning. We meticulously clean both internal components, such as fans and heatsinks, and external surfaces to prevent dust buildup that can lead to overheating. For precision equipment like printers and sensors, we recalibrate settings to maintain accuracy and efficiency.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/HardwareMaintenance/Reporting.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Reporting & Documentation</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                After each maintenance session, we provide detailed reports on the health of your systems and any actions taken. These reports include comprehensive assessments of each component, with a maintenance log capturing every action. We offer recommendations for further improvements and adjustments, and provide compliance documentation if required, ensuring your systems meet industry standards.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/HardwareMaintenance/support.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Emergency Support & Repair</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                Unexpected hardware issues can arise at any time, and when they do, our emergency support and repair services are ready to respond swiftly.Our team is available 24/7 to address urgent hardware failures, minimizing downtime.We offer both on-site repairs for physical hardware issues and remote support for software or configuration problems, ensuring that you get the help you need when you need it. </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Upgrade Service</h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Technology evolves rapidly, and so should your hardware. TechX offers comprehensive upgrade services to ensure your infrastructure keeps pace with the latest advancements, providing you with enhanced performance, security, and capabilities.
                </p>
                <div className="row nm-style4 mt-sm-2 mt-1">
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Hardware Upgrades</h3>
                        <p data-aos="fade-rown">
                            We assist with the replacement and upgrade of outdated hardware components, including processors, memory, storage devices, and networking equipment. Our team ensures that your systems are up-to-date with the latest technology, providing enhanced performance and reliability.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">System Reconfiguration</h3>
                        <p data-aos="fade-rown">
                            Our experts reconfigure your systems to accommodate new hardware, ensuring compatibility and optimal performance. We fine-tune system settings, BIOS configurations, and network parameters to seamlessly integrate new components into your existing infrastructure.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Data Migration</h3>
                        <p data-aos="fade-rown">
                            Seamless data migration services ensure that all your important information is safely transferred to new systems without any loss or downtime. We handle everything from transferring files to setting up new data structures, ensuring a smooth transition.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Software Compatibility Checks</h3>
                        <p data-aos="fade-rown">
                            We ensure that your upgraded hardware is fully compatible with existing software applications, minimizing disruptions during the transition. Our team tests and adjusts configurations as needed to maintain a smooth and stable operating environment.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Testing & Validation</h3>
                        <p data-aos="fade-rown">
                            Post-upgrade testing is conducted to validate the performance and stability of your newly upgraded systems. We rigorously test all hardware components and system integrations to ensure that everything is functioning at its best.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Warranty & Support Management</h3>
                        <p data-aos="fade-rown">
                            After upgrading, we assist with managing the warranties and support services for your new hardware. This includes registering new components, tracking warranty periods, and liaising with manufacturers for any support needs, ensuring you have full coverage and peace of mind.
                        </p>
                    </div>



                </div>
            </div>

            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}
export default HardwareMaintenance