import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ConsultandManagement = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>

            <div className="container py-md-5 py-2">
                <div className="row d-lg-flex d-none">
                    <div className="col-lg-6  d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40 mr-xl-2" data-aos="fade-up" >Consultancy & Management</h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-down">
                            At TechX, our Consultancy & Management services are designed to help your business navigate the complexities of modern technology. Whether you're planning to implement new systems, optimize existing operations, or manage large-scale IT projects, our team of experts is here to guide you every step of the way.
                        </p>
                    </div>
                    <div className="col-lg-6 p-lg-0  px-md-2 d-flex" data-aos="fade-left">
                        <img src="assets/images/pages/ConsultandManagement/consultant.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row d-lg-none d-flex">

                    <div className="col-lg-6 p-lg-0  px-md-2 d-flex" data-aos="fade-left">
                        <img src="assets/images/pages/ConsultandManagement/consultant.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6  d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40 ml-xl-2" data-aos="fade-up" >Consultancy & Management </h3>
                        <p className="textColor f_18 text-justify ml-xl-2" data-aos="fade-down">
                            At TechX, our Consultancy & Management services are designed to help your business navigate the complexities of modern technology. Whether you're planning to implement new systems, optimize existing operations, or manage large-scale IT projects, our team of experts is here to guide you every step of the way.  </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">
                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about f_25 mb-1 w-100 text-center" data-aos="fade-right" >IT Strategy & Planning</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                        Develop a robust IT strategy that aligns with your business goals and sets the stage for long-term success.
                    </p>
                    <div className="col-lg-6 d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-right">
                        <img src="assets/images/pages/ConsultandManagement/planning.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center ">
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-left"><span className="color-about">Strategic Roadmapping</span> : Craft a clear, actionable plan for your IT initiatives.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-left"><span className="color-about">Technology Assessment</span> : Evaluate current systems and identify areas for improvement.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-left"><span className="color-about">Innovation Consulting</span> : Explore emerging technologies to stay ahead of the curve.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-left"><span className="color-about">Risk Management</span> : Identify and mitigate potential risks to ensure seamless execution.</p>
                        <p className="f_18 textColor text-start mb-1 ml-lg-2 " data-aos="fade-left"><span className="color-about">Resource Optimization</span> : Maximize the value of your IT investments.</p>
                    </div>
                </div>
                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about mt-lg-5 mt-3 f_25 mb-1 w-100 text-center" data-aos="fade-right" >Project Management</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                        Ensure the successful execution of your IT projects with our comprehensive project management services.  </p>
                    <div className="col-lg-6 d-lg-flex d-none flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">End-to-End Management</span> : Oversee all phases of your project from inception to completion.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Agile Methodology</span> : Implement agile practices for faster delivery and increased flexibility.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Stakeholder Communication</span> : Maintain clear and consistent communication with all stakeholders.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Quality Assurance</span> : Ensure that all deliverables meet the highest standards of quality.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Budget & Timeline Management</span> : Keep your project on time and within budget.</p>
                    </div>
                    <div className="col-lg-6 d-lg-flex d-none p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/ConsultandManagement/manage.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-lg-none d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/ConsultandManagement/manage.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-lg-none d-flex flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">End-to-End Management</span> : Oversee all phases of your project from inception to completion.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Agile Methodology</span> : Implement agile practices for faster delivery and increased flexibility.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Stakeholder Communication</span> : Maintain clear and consistent communication with all stakeholders.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Quality Assurance</span> : Ensure that all deliverables meet the highest standards of quality.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Budget & Timeline Management</span> : Keep your project on time and within budget.</p>

                    </div>
                </div>




            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">

                <div className="row  mb-md-4 mb-2 ">
                    <div className="col-lg-6 mt-lg-4 mt-2 d-flex p-lg-0 px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/ConsultandManagement/infra.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 mt-lg-4 mt-2 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className="color-about  f_25 mb-1 text-center" data-aos="fade-left">IT Infrastructure Management</h3>
                        <p className="textColor f_18 text-justify ml-xl-2" data-aos="fade-left">Optimize and maintain your IT infrastructure to support your business operations efficiently.
                            <p className="f_18 textColor text-justify mb-1  " data-aos="fade-up">Our Infrastructure Design services create scalable, robust systems tailored to your unique business needs, ensuring a strong foundation for your operations. By leveraging Cloud Management, we offer increased flexibility and cost savings, while our Network Optimization enhances both performance and security. We ensure the reliability and efficiency of your operations through comprehensive Data Center Management, and our Disaster Recovery strategies are designed to protect your business from unexpected disruptions, providing peace of mind in the face of challenges.

                            </p>

                        </p>
                    </div>
                </div>

                <div className="row mb-2 mt-lg-5 d-lg-flex d-none">
                    <div className="col-lg-6  mt-lg-5  d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 text-center mb-1 mr-xl-2" data-aos="fade-left">Change Management </h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            Facilitate smooth transitions during organizational changes with our expert change management services.
                        </p>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                        Our Change Planning services develop a structured approach to manage transitions effectively, ensuring that your business adapts smoothly to new systems. We equip your team with the necessary skills through comprehensive Employee Training, while our Communication Strategy keeps your workforce informed and engaged throughout the process. By addressing and mitigating resistance to change through Resistance Management, we help facilitate a seamless transition. Additionally, our Post-Implementation Support provides ongoing assistance to ensure the successful adoption of new practices.
                        </p>
                    </div>
                    <div className="col-lg-6 d-flex mt-lg-5  p-lg-0  px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/ConsultandManagement/change.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row mb-2 d-flex d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0   px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/ConsultandManagement/change.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about mb-1 text-center f_25" data-aos="fade-left">Change Management</h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            Facilitate smooth transitions during organizational changes with our expert change management services.
                        </p>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                        Our Change Planning services develop a structured approach to manage transitions effectively, ensuring that your business adapts smoothly to new systems. We equip your team with the necessary skills through comprehensive Employee Training, while our Communication Strategy keeps your workforce informed and engaged throughout the process. By addressing and mitigating resistance to change through Resistance Management, we help facilitate a seamless transition. Additionally, our Post-Implementation Support provides ongoing assistance to ensure the successful adoption of new practices.
                        </p>
                    </div>
                </div>

            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">

                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about f_25 mb-1 w-100 text-center" data-aos="fade-right" >Business Process Optimization</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                    Streamline your operations to improve efficiency, reduce costs, and enhance customer satisfaction. </p>
                    <div className="col-lg-6 d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/ConsultandManagement/plan.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center ">
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Process Analysis</span> : Evaluate current workflows to identify inefficiencies.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Automation Solutions</span> : Implement automation tools to eliminate manual tasks.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Lean Management</span> : Apply lean principles to reduce waste and increase productivity.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Performance Metrics</span> : Monitor and measure performance to drive continuous improvement.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Customer Experience Enhancement</span> : Optimize processes to deliver better service and value to your customers.</p>
                    </div>
                </div>
            </div>






            <div className="se-head mt-sm-3 mt-2 mb-5">
                <h3 className="se-title-1">FAQS</h3>
                <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                <Link to="/support"
                    className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                >
                    Support
                </Link>
            </div>
            <ThemeSetting />

        </>
    )
}

export default ConsultandManagement