
import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const NetworkComm = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div className="container pt-lg-4 pb-lg-4 py-3">
                <div className="row">

                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/NetworkComm/netbanner.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40 ml-lg-1" data-aos="fade-up" >Network & Communication Hardware</h3>
                        <p className="textColor f_18 text-justify ml-lg-1" data-aos="fade-down">At TechX, we offer cutting-edge Network & Communication Hardware designed to keep your business connected, secure, and efficient. Our comprehensive range of solutions ensures optimal performance, reliability, and scalability for all your networking and communication needs.  </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-3">
                <div className="row mb-2 d-lg-flex d-none py-lg-3">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="mr-lg-1 color-about f_25 mb-1" data-aos="fade-right" >Network Switches & Routers</h3>
                        <p className=" mr-lg-1 textColor f_18 text-justify" data-aos="fade-down">
                            Our high-performance network switches and routers are engineered to deliver robust connectivity and unparalleled data management.
                        </p>
                        <p className="mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>High-Speed Performance</span> : Ensure swift and reliable data transfer across your network.</p>
                        <p className="mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Advanced Security Features</span> : Protect your network from unauthorized access and cyber threats.</p>
                        <p className="mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Scalable Solutions</span> : Easily expand your network infrastructure as your business grows.</p>
                        <p className="mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Smart Management</span> : Intuitive interfaces for easy configuration and monitoring.</p>
                        <p className="mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Redundancy and Reliability</span> : Minimize downtime with fault-tolerant designs.</p>

                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/NetworkComm/switch.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block py-1">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/NetworkComm/switch.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-right" >Network Switches & Routers</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down">
                            Our high-performance network switches and routers are engineered to deliver robust connectivity and unparalleled data management.
                        </p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>High-Speed Performance</span> : Ensure swift and reliable data transfer across your network.</p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Advanced Security Features</span> : Protect your network from unauthorized access and cyber threats.</p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Scalable Solutions</span> : Easily expand your network infrastructure as your business grows.</p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Smart Management</span> : Intuitive interfaces for easy configuration and monitoring.</p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Redundancy and Reliability</span> : Minimize downtime with fault-tolerant designs.</p>
                    </div>
                </div>


                <div className="row mb-2 py-lg-3">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/NetworkComm/wireless.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className=" ml-lg-1 color-about f_25 mb-1" data-aos="fade-left">Wireless Access Points</h3>
                        <p className=" ml-lg-1 textColor f_18 text-justify" data-aos="fade-left">Stay connected with our top-of-the-line wireless access points that offer extensive coverage and high-speed connectivity.
                        </p>
                        <p className=" ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Seamless Coverage</span> : Extend your wireless network across large areas with minimal dead zones.</p>
                        <p className=" ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>High-Speed Connectivity</span> : Support high-bandwidth applications with reliable performance.</p>
                        <p className=" ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Enhanced Security</span> : Protect your wireless network with advanced encryption and authentication protocols.</p>
                        <p className=" ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>High Density Support</span> : Handle multiple connections simultaneously with high efficiency.</p>

                    </div>
                </div>

                <div className="row py-lg-3 mb-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className=" mr-lg-1 color-about f_25 mb-1" data-aos="fade-left">Network Security Appliances</h3>
                        <p className=" mr-lg-1 textColor f_18 text-justify" data-aos="fade-left">Safeguard your network with our comprehensive range of network security appliances.
                        </p>
                        <p className=" mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Advanced Firewalls</span> : Block malicious traffic and unauthorized access with state-of-the-art firewalls.</p>
                        <p className=" mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Intrusion Detection Systems</span> : Monitor and respond to suspicious activity in real-time.</p>
                        <p className=" mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Regular Updates</span> : Stay protected with continuous updates and threat intelligence.</p>
                        <p className=" mr-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Secure Remote Access</span> : Ensure safe connectivity for remote users with VPN solutions.</p>

                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/NetworkComm/securityData.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/NetworkComm/securityData.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="  color-about f_25 mb-1" data-aos="fade-left">Network Security Appliances</h3>
                        <p className=" textColor f_18 text-justify" data-aos="fade-left">Safeguard your network with our comprehensive range of network security appliances.
                        </p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Advanced Firewalls</span> : Block malicious traffic and unauthorized access with state-of-the-art firewalls.</p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Intrusion Detection Systems</span> : Monitor and respond to suspicious activity in real-time.</p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Regular Updates</span> : Stay protected with continuous updates and threat intelligence.</p>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Secure Remote Access</span> : Ensure safe connectivity for remote users with VPN solutions.</p>

                    </div>
                </div>

                <div className="row py-lg-3 mb-2 ">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/NetworkComm/Communication.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="ml-lg-1 mb-1 color-about f_25" data-aos="fade-right">Communication Hardware</h3>
                        <p className="ml-lg-1 textColor f_18 text-justify" data-aos="fade-left">Enhance your communication capabilities with our high-quality communication hardware.
                        </p>
                        <p className="ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Video Conferencing Systems</span> : Facilitate seamless virtual meetings with high-definition video and audio.</p>
                        <p className="ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Integrated Solutions</span> : Combine voice, video, and data communication into a unified platform.</p>
                        <p className="ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>User-Friendly Interfaces</span> : Simplify communication management with intuitive controls.</p>
                        <p className="ml-lg-1 f_18 textColor text-justify" data-aos="fade-up"><span style={{ color: "#2edaf1" }}>Scalability</span> : Expand your communication systems to accommodate growing teams.</p>

                    </div>
                </div>
            </div>

            <hr style={{ color: '#e2e9ee' }} />


            <div className="container pt-lg-3 mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Why Choose TechX?
                </h3>


                <div className="row nm-style4 mt-sm-2 mt-1 py-lg-2 py-md-1">
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-sm-2 mb-1 ">
                        <h3 className="textColor">Tailored Solutions
                        </h3>
                        <p data-aos="fade-rown">Get customized hardware solutions designed to meet your unique networking and communication needs. </p>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Expert Support</h3>
                        <p data-aos="fade-rown">Rely on our team for expert guidance and support from installation to ongoing maintenance.</p>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Premium Quality</h3>
                        <p data-aos="fade-rown">Access high-quality hardware from leading manufacturers to ensure durability and performance. </p>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Scalable Designs</h3>
                        <p data-aos="fade-rown">Benefit from solutions that grow with your business, adapting to evolving needs and technologies. </p>
                    </div>




                </div>

            </div>

            <div className="se-head mt-sm-3 mt-2 mb-5">
                <h3 className="se-title-1">FAQS</h3>
                <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                <Link to="/support"
                    className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                >
                    Support
                </Link>
            </div>

            <ThemeSetting />
        </>
    )
}

export default NetworkComm