import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ITInfrastructure = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>

            <div className="container py-md-5 py-2">
                <div className="row d-lg-flex d-none">
                    <div className="col-lg-6  d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40 mr-xl-2" data-aos="fade-up" >Comprehensive IT Infrastructure Solutions</h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-down">
                            At TechX, we provide robust and scalable IT infrastructure solutions designed to meet the evolving needs of your business. Whether you're building a new infrastructure from the ground up or enhancing an existing one, our expertise ensures that your systems are secure, reliable, and optimized for performance.  </p>
                    </div>
                    <div className="col-lg-6 p-lg-0  px-md-2 d-flex" data-aos="fade-left">
                        <img src="assets/images/pages/ITInfrastructure/ithome.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row d-lg-none d-flex">

                    <div className="col-lg-6 p-lg-0  px-md-2 d-flex" data-aos="fade-left">
                        <img src="assets/images/pages/ITInfrastructure/ithome.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6  d-flex flex-column justify-content-center align-items-center ">
                        <h3 className="color-about F_40 ml-xl-2" data-aos="fade-up" >Comprehensive IT Infrastructure Solutions </h3>
                        <p className="textColor f_18 text-justify ml-xl-2" data-aos="fade-down">
                            At TechX, we provide robust and scalable IT infrastructure solutions designed to meet the evolving needs of your business. Whether you're building a new infrastructure from the ground up or enhancing an existing one, our expertise ensures that your systems are secure, reliable, and optimized for performance.  </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">
                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about f_25 mb-1 w-100 text-center" data-aos="fade-right" >Data Center Solutions</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                        We design, implement, and manage state-of-the-art data center solutions tailored to your specific business needs. Our services include:  </p>
                    <div className="col-lg-6 d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-right">
                        <img src="assets/images/pages/ITInfrastructure/database.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Custom Data Center Design</span> : We create scalable data center architectures that optimize space, power, and cooling efficiency while accommodating future growth.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">High-Availability Infrastructure</span> : Ensuring uptime is crucial, and our solutions focus on redundancy, load balancing, and failover capabilities to keep your operations running smoothly.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Energy Efficiency & Cooling Solutions</span> : We implement energy-efficient systems and advanced cooling technologies to reduce operational costs and minimize your environmental footprint.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Security & Compliance</span> : Our data center solutions include robust security measures and compliance with industry regulations, protecting your sensitive data from potential threats.</p>
                    </div>
                </div>
                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about mt-lg-5 mt-3 f_25 mb-1 w-100 text-center" data-aos="fade-right" >Server Installation & Management</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                        Our server installation and management services ensure that your servers are properly configured, maintained, and optimized for maximum performance.  </p>
                    <div className="col-lg-6 d-lg-flex d-none flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Custom Server Deployment</span> : We assess your business requirements and deploy servers that meet your specific needs, whether on-premises or in the cloud.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Ongoing Server Management</span> : Our team handles all aspects of server management, including performance monitoring, updates, and troubleshooting, ensuring your servers run smoothly around the clock.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Virtualization & Cloud Integration</span> : We integrate virtualization technologies and cloud platforms to enhance server efficiency, scalability, and resource utilization.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Security & Patch Management</span> : We regularly update your servers with the latest security patches and software updates, protecting your systems from vulnerabilities.</p>
                    </div>
                    <div className="col-lg-6 d-lg-flex d-none p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/ITInfrastructure/server.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-lg-none d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/ITInfrastructure/server.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-lg-none d-flex flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Custom Server Deployment</span> : We assess your business requirements and deploy servers that meet your specific needs, whether on-premises or in the cloud.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Ongoing Server Management</span> : Our team handles all aspects of server management, including performance monitoring, updates, and troubleshooting, ensuring your servers run smoothly around the clock.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Virtualization & Cloud Integration</span> : We integrate virtualization technologies and cloud platforms to enhance server efficiency, scalability, and resource utilization.</p>
                        <p className="f_18 textColor text-justify mb-1 mr-lg-2 " data-aos="fade-up"><span className="color-about">Security & Patch Management</span> : We regularly update your servers with the latest security patches and software updates, protecting your systems from vulnerabilities.</p>
                    </div>
                </div>




            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">

                <div className="row  mb-md-4 mb-2 ">
                    <div className="col-lg-6 mt-lg-4 mt-2 d-flex p-lg-0 px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/ITInfrastructure/storage.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 mt-lg-4 mt-2 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className="color-about  f_25 mb-1 text-center" data-aos="fade-left">Storage Solutions</h3>
                        <p className="textColor f_18 text-justify ml-xl-2" data-aos="fade-left">Efficient and secure storage solutions are critical for managing your data effectively. We offer a range of storage options that can be tailored to your business needs.
                            <p className="f_18 textColor text-justify mb-1  " data-aos="fade-up">Our solutions include scalable storage architectures that grow with your business, from SANs to NAS and cloud storage options. We implement data tiering strategies that ensure critical data is stored on high-performance systems, while less frequently accessed data is archived on cost-effective storage. Our storage solutions include built-in redundancy and disaster recovery options, ensuring that your data is protected and accessible, even in the event of hardware failure.

                            </p>

                        </p>
                    </div>
                </div>

                <div className="row mb-2 mt-lg-5 d-lg-flex d-none">
                    <div className="col-lg-6  mt-lg-5  d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1 mr-xl-2" data-aos="fade-left">Backup & Recovery Hardware </h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            Data loss can be catastrophic for any business. Our backup and recovery hardware solutions ensure that your data is always protected and can be quickly restored when needed.
                        </p>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            We design and implement automated backup solutions that regularly save your data, reducing the risk of loss due to human error or hardware failure. In the event of data loss, our recovery hardware allows for rapid restoration, minimizing downtime and getting you back to business quickly. We offer offsite and cloud-based backup options, providing additional layers of security and redundancy for your critical data. Our experts work with you to develop and implement a data recovery plan tailored to your business needs, ensuring that you’re prepared for any scenario.
                        </p>
                    </div>
                    <div className="col-lg-6 d-flex mt-lg-5  p-lg-0  px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/ITInfrastructure/backup.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                </div>
                <div className="row mb-2 d-flex d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0   px-md-2" data-aos="fade-right">
                        <img src="assets/images/pages/ITInfrastructure/backup.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about mb-1  f_25" data-aos="fade-left">Backup & Recovery Hardware</h3>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            Data loss can be catastrophic for any business. Our backup and recovery hardware solutions ensure that your data is always protected and can be quickly restored when needed.
                        </p>
                        <p className="textColor f_18 text-justify mr-xl-2" data-aos="fade-left">
                            We design and implement automated backup solutions that regularly save your data, reducing the risk of loss due to human error or hardware failure. In the event of data loss, our recovery hardware allows for rapid restoration, minimizing downtime and getting you back to business quickly. We offer offsite and cloud-based backup options, providing additional layers of security and redundancy for your critical data. Our experts work with you to develop and implement a data recovery plan tailored to your business needs, ensuring that you’re prepared for any scenario.
                        </p>
                    </div>
                </div>

            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-xl-5 py-sm-3 py-2">

                <div className="row mb-md-4 mb-2 ">
                    <h3 className="color-about f_25 mb-1 w-100 text-center" data-aos="fade-right" >Network Infrastructure</h3>
                    <p className="textColor f_18 w-100 text-center mb-lg-3 mb-2" data-aos="fade-up">
                        A robust and reliable network is the backbone of any IT infrastructure. We design, implement, and manage network solutions that ensure seamless connectivity and communication across your organization. </p>
                    <div className="col-lg-6 d-flex p-lg-0  px-md-2 mb-lg-0 mb-2" data-aos="fade-left">
                        <img src="assets/images/pages/ITInfrastructure/network.jpg" className="w-100" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Custom Network Design</span> : We create network architectures tailored to your specific needs, whether it's for a small office or a large enterprise with multiple locations.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Advanced Security Features</span> : Our network solutions include state-of-the-art security features such as firewalls, intrusion detection systems, and VPNs to protect your data and ensure safe communication.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Scalable Solutions</span> : As your business grows, so too can your network. We provide scalable network solutions that can easily adapt to your changing needs.</p>
                        <p className="f_18 textColor text-justify mb-1 ml-lg-2 " data-aos="fade-up"><span className="color-about">Performance Monitoring & Optimization</span> : Continuous monitoring and optimization of your network ensure that it runs at peak performance, with minimal latency and maximum uptime.</p>
                    </div>
                </div>
            </div>






            <div className="se-head mt-sm-3 mt-2 mb-5">
                <h3 className="se-title-1">FAQS</h3>
                <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                <Link to="/support"
                    className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                >
                    Support
                </Link>
            </div>
            <ThemeSetting />

        </>
    )
}

export default ITInfrastructure