import ThemeSetting from "./Include/ThemeSetting"


const ActStatement = ()=>{
    return(
        <>
         <div className="se-i-support py-120">
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-center">Modern Slavery Act Statement</h1>
                        <p className="para-1 text-center">TechX is committed to ensuring that modern slavery and human trafficking are not taking place within our business or supply chains. This statement outlines the steps we have taken to prevent modern slavery and human trafficking in compliance with the UK Modern Slavery Act 2015.</p>
                    </div>

                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-center" style={{ fontSize: '28px', marginTop: '45px' }}>Our Policies</h1>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Code of Conduct : </strong>Our Code of Conduct reflects our commitment to acting ethically and with integrity in all our business relationships. It sets out the standards we expect from our employees and suppliers.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Supplier Code of Conduct : </strong>We expect our suppliers to adhere to the same high standards. Our Supplier Code of Conduct prohibits the use of forced, compulsory, or trafficked labor, or anyone held in slavery or servitude.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-center" style={{ fontSize: '28px', marginTop: '45px' }}>Due Diligence Processes</h1>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Risk Assessment : </strong> We conduct risk assessments to identify and address potential areas of concern in our supply chains.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Supplier Audits : </strong> We carry out audits and assessments of our suppliers to ensure compliance with our standards on modern slavery and human trafficking.</p>
                        <p className="para-1 text-start" style={{ marginBottom: '15px' }}><strong style={{ fontSize: '18px' }}>Training : </strong> We provide training to our employees to ensure they understand the risks of modern slavery and human trafficking and can identify and report any concerns.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-center" style={{ fontSize: '28px', marginTop: '45px' }}>Ongoing Commitment</h1>
                        <p className="para-1 text-center">TechX is dedicated to continuous improvement in our efforts to combat modern slavery and human trafficking. We will regularly review and update our policies and practices to ensure they are effective and reflect current best practices.
                        </p>

                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <h1 className="title-1 text-center" style={{ fontSize: '28px', marginTop: '45px' }}>Contat Us</h1>
                        <p className="para-1 text-center">For any questions or concerns regarding this Modern Slavery Act Statement, please contact us at  <a  style={{textDecoration: 'none', borderBottom: '1px solid #999999' , color:"#1344ff"}}>compliance@techx.live</a>
                        </p>

                    </div>
                </div>
                <div className="container mt-5">
                    <div className="content">
                        <p className="para-1 text-center" style={{fontSize:'22px'}}>
                        By adhering to these policies and statements, TechX is committed to maintaining the highest ethical standards and ensuring compliance with UK and global laws.
                        </p>

                    </div>
                </div>
            </div>

            <ThemeSetting />
        </>
    )


}

export default ActStatement