import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DigitalTransformation = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(/assets/images/pages/DigitalTransformation/digital1.PNG)`,
                    minHeight: '100%',
                    backgroundSize: "cover"
                }}
            >
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-8">
                            <h3
                                className="AI-text-title-one white-theme-text-AI color-about " data-aos="fade-up"
                            >
                                Digital Transformation
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <p className="f_22 textColor text-justify" data-aos="fade-down">At  <span className="heading-text-AI-banner " style={{ fontSize: 22 }}>TechX</span>, we drive Digital Transformation by enabling businesses to fully embrace and integrate digital technologies into every aspect of their operations. From enhancing customer experiences to streamlining internal processes, our tailored solutions help your organization adapt, evolve, and thrive in the digital age. We work with you to develop a comprehensive digital strategy that aligns with your business goals, ensuring that your transformation journey is smooth, efficient, and impactful.</p>
            </div>
            <hr style={{ color: '#e2e9ee' }} />
            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1"> Strategic Planning and Implementation</h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Our Strategic Planning and Implementation service lays the foundation for your digital transformation by developing a customized roadmap tailored to your unique needs. We work closely with you to assess your current technological landscape, identify opportunities for improvement, and implement solutions that drive your business forward.   </p>
                <div className="row text-center">
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-3">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/DigitalTransformation/need.jpeg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h3 className="box-title">Needs Assessment
                            </h3>
                            <p className="box-para text-justify textColor" data-aos="fade-down">
                                We begin by conducting a comprehensive needs assessment to thoroughly understand your business objectives, challenges, and existing technology infrastructure. This involves engaging with key stakeholders to identify pain points, opportunities, and future goals. We assess your current digital maturity and determine where technology can best support your strategic objectives.  </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-5">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/DigitalTransformation/techass.jpeg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Technology Alignment</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Our team meticulously evaluates potential digital solutions to ensure they align with your long-term business goals. This involves assessing the compatibility of new technologies with your existing systems, identifying opportunities for integration, and ensuring that selected solutions can scale with your business as it grows. </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/DigitalTransformation/roadmap.jpeg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Roadmap Development</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>We create a detailed, step-by-step roadmap that outlines the key milestones and deliverables required to achieve a successful digital transformation. This roadmap serves as a strategic guide, detailing the sequence of initiatives, the timeline for implementation, and the resources required at each stage.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/DigitalTransformation/resource.jpeg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title"> Resource Planning</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Successful digital transformation hinges on the effective planning and allocation of resources, including technology, personnel, and budget. We work closely with you to identify the resources required at each stage of the transformation, ensuring that you have the right tools and teams in place.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/EnterpriseHardware/management.jpg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Risk Management</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>Digital transformation involves navigating a complex landscape of potential risks, from technological disruptions to operational challenges. Our approach includes a thorough risk management strategy, where we identify potential risks early in the process and develop mitigation plans to address them.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-sm-3 mb-2 px-xl-2" data-aos="fade-down">
                        <div className="box color-2">
                            <a href="#" className="box-link"></a>
                            <img
                                src={"/assets/images/pages/DigitalTransformation/matrix.jpeg"}
                                className=" img-fluid"
                                alt="Image" height={240} width={450}
                            />
                            <h4 className="box-title">Performance Metrics</h4>
                            <p className="box-para text-justfiy textColor" data-aos="fade-down" style={{ textAlign: 'justify' }}>
                                To ensure that your digital transformation delivers measurable results, we establish key performance indicators (KPIs) that are aligned with your business objectives. These metrics allow us to monitor the progress of the transformation, assess the effectiveness of implemented solutions, and make data-driven adjustments as needed.</p>
                        </div>
                    </div>
                </div>
            </div>

            <hr style={{ color: '#e2e9ee' }} />

            <div className="container mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Process Optimization</h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Digital Transformation goes beyond merely adopting new technologies; it’s about fundamentally rethinking how your business operates. Our Process Optimization service is designed to refine and automate your business processes, enhancing efficiency, reducing costs, and improving agility. By analyzing your existing workflows and identifying inefficiencies, we implement digital tools that streamline operations, ensuring your business is well-positioned to adapt to market changes and drive continuous improvement and innovation.
                </p>
                <div className="row">
                    <div className="col-lg-6 d-flex  " data-aos="fade-zoom-in">
                        <img src="assets/images/pages/DigitalTransformation/process.jpeg" className="w-100" data-aos="fade-zoom-in" style={{ borderRadius: 15 }} />
                    </div>
                    <div className="col-lg-6 d-flex  flex-column justify-content-center py-lg-2 align-items-center" style={{ gap: 10 }}>
                        <p className="f_18 textColor text-justify" data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Workflow Analysis</strong> : We start by conducting a thorough analysis of your current workflows to identify bottlenecks and inefficiencies. This deep dive into your operations allows us to pinpoint areas where processes can be optimized and leading to faster</p>
                        <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Automation Implementation</strong> : Our team introduces automation solutions tailored to your business needs, helping you reduce manual effort and minimize errors. By automating routine tasks, we free up your team to focus on more strategic activities and  boosting productivity.</p>
                        <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Cost Reduction Strategies</strong> : Through process optimization, we identify opportunities to reduce costs without compromising quality or efficiency. Whether it’s streamlining supply chains, optimizing resource allocation, or leveraging digital tools.</p>
                        {/* <p className="f_18 textColor text-justify " data-aos="fade-up"><strong style={{ color: "#2edaf1" }}>Agility Enhancement</strong> : In today’s fast-paced market, agility is key. We implement process improvements that increase your business’s ability to respond quickly to changes in the market, customer demands, and technological advancements. </p> */}
                    </div>
                </div>
            </div>

            <div className="container pt-lg-3 mt-sm-5 mt-2 mb-sm-5 mb-2">
                <h3 className="F_40 color-about text-center mb-sm-2 mb-1">Customer Experience Enhancement
                </h3>
                <p className="f_18 textColor text-justify mb-sm-2 mb-1">
                    Customer experience is at the heart of any successful digital transformation. At TechX, we prioritize enhancing customer interactions by leveraging digital tools and platforms to create seamless, personalized experiences
                </p>


                <div className="row nm-style4 mt-sm-2 mt-1 py-lg-2 py-md-1">
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1 ">
                        <h3 className="textColor">Multi-Channel Engagement
                        </h3>
                        <p data-aos="fade-rown">We enable your business to connect with customers across various channels, providing consistent and engaging experiences whether online, on mobile, or in person. </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Personalization Strategy</h3>
                        <p data-aos="fade-rown">By using data-driven insights, we tailor customer experiences to individual preferences, increasing satisfaction and fostering brand loyalty.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Customer Journey Mapping</h3>
                        <p data-aos="fade-rown">We map out the entire customer journey, identifying key touchpoints and opportunities to enhance interactions and deliver exceptional service. </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Feedback Integration</h3>
                        <p data-aos="fade-rown">Continuous improvement is driven by customer feedback. We implement systems that allow for real-time feedback integration, helping you refine your offerings. </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Automation of Customer Interactions</h3>
                        <p data-aos="fade-rown">We leverage automation tools to streamline customer interactions, reducing response times and improving the overall customer experience. </p>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-sm-2 mb-1">
                        <h3 className="textColor">Data Security in Customer Transactions</h3>
                        <p data-aos="fade-rown">We ensure that all customer interactions are secure, with robust data protection measures in place to safeguard sensitive information. </p>
                    </div>




                </div>

            </div>


            <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default DigitalTransformation