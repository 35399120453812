/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import Cookie from "./Include/Cookie";
import ThemeSetting from "./Include/ThemeSetting";
//import Card from '../../Components/UI/Card';
import {
    FaShieldAlt,
    FaGlobe,
    FaClipboardCheck,
    FaGoogleDrive,
    FaUbuntu,
    FaRandom,
} from "react-icons/fa";
import "./styles/CloudService.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const APIDevelop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const baseUrl = location.href.includes("trainingncr.info")
        ? `http://trainingncr.info/techx/assets/images`
        : `/assets/images`;
    return (
        <>
            <div className="container pt-3 webhosting-all-section ">
                <div className="row d-flex py-3">
                    <div className="col-lg-6">
                        <img
                            src="assets/images/pages/APIDevelop/api.jpeg"
                            className=" img-fluid"
                            alt="Image"
                            style={{ borderRadius: 15 }}
                        />
                    </div>
                    <div className="col-lg-6 d-flex align-items-center flex-column justify-content-center">
                        <h2 className=" AI-text-title-one white-theme-text-AI color-about mt-1">
                            API Development & Integration
                        </h2>
                        <p className="AI-text-title-three mt-1 mb-1">
                            At TechX, we specialize in crafting powerful and seamless API solutions that enhance the functionality of your digital ecosystem. Our API Development & Integration services are designed to connect your software systems, enabling them to communicate and share data efficiently. Whether you need to build custom APIs from scratch or integrate third-party APIs into your existing platforms, our team of experts is here to deliver solutions that are robust, secure, and scalable. We ensure that your APIs not only meet current needs but also support future growth, providing a solid foundation for your business's digital transformation
                        </p>
                    </div>
                    {/* <div className="col-lg-6 cls-img d-flex">
                        <div className="image-aboutus-section">
                            <img
                                src="assets/images/pages/APIDevelop/api.jpeg"
                                className=" img-fluid"
                                alt="Image"
                                style={{ borderRadius: 15 }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="aboutus-text-section" style={{ marginLeft: 0 }}>
                            <h2 className=" AI-text-title-one white-theme-text-AI color-about mt-1">
                                API Development & Integration
                            </h2>
                            <p className="AI-text-title-three mt-1 mb-1">
                                At TechX, we specialize in crafting powerful and seamless API solutions that enhance the functionality of your digital ecosystem. Our API Development & Integration services are designed to connect your software systems, enabling them to communicate and share data efficiently. Whether you need to build custom APIs from scratch or integrate third-party APIs into your existing platforms, our team of experts is here to deliver solutions that are robust, secure, and scalable. We ensure that your APIs not only meet current needs but also support future growth, providing a solid foundation for your business's digital transformation
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="se-iv-home py-90 bg-2">
                <div className="container">
                    {/* <!-- se-head --> */}
                    <div className="se-head" style={{ maxWidth: 'auto' }}>
                        <h3 className="se-title-1">API Development</h3>
                        <h4 className="se-title-2">
                            We design and develop custom APIs tailored to your specific business requirements. Our APIs are built to facilitate smooth communication between different software systems, ensuring data flows seamlessly across your organization. We focus on creating APIs that are scalable, secure, and easy to maintain, enabling your business to expand and adapt to new challenges without being constrained by technology.
                        </h4>
                    </div>
                    {/* <!-- space --> */}
                    <div className="space space-sm"></div>
                    {/* <!-- row --> */}
                    <div className="row text-center mt-2">
                        {/* <!-- col --> */}

                        <div className="col-xl-6 col-lg-6 col-md-12 mb-2">
                            {/* <!-- box --> */}
                            <div className="box color-3">
                                {/* <!-- link --> */}
                                <a href="#" className="box-link"></a>
                                {/* <!-- icon --> */}
                                <div className="icon icon-style color3 m-auto">
                                    <FaGoogleDrive />
                                    {/* <img src="/assets/images/icons/mission.svg" className="lazy img-fluid" alt="mission" /> */}
                                </div>
                                {/* <!-- box-title --> */}
                                <h3 className="box-title">API Management & Monitoring</h3>
                                {/* <!-- box-para --> */}
                                <p className="box-para" style={{ display: "flex", height: 'auto', maxHeight: 'max-content' }}>
                                    Effective API management is crucial for maintaining the performance and security of your integrations. Our API Management & Monitoring services include setting up tools and protocols to monitor API usage, track performance metrics, and ensure that your APIs are functioning optimally.
                                </p>

                            </div>
                        </div>
                        {/* <!-- col --> */}
                        <div className="col-xl-6 col-lg-6 col-md-12 mb-2">
                            {/* <!-- box --> */}
                            <div className="box color-5">
                                {/* <!-- link --> */}
                                <a href="#" className="box-link"></a>
                                {/* <!-- icon --> */}
                                <div className="icon icon-style color3 m-auto">
                                    <FaUbuntu />
                                    {/* <img src="/assets/images/icons/data-storage-device.svg" className="lazy img-fluid" alt="data-storage-device" /> */}
                                </div>
                                {/* <!-- box-title --> */}
                                <h4 className="box-title">Security & Compliance</h4>
                                {/* <!-- box-para --> */}
                                <p className="box-para" style={{ display: "flex", height: 'auto', maxHeight: 'max-content' }}>
                                    Security is a top priority in all our API development and integration projects. We implement industry-standard security measures to protect your APIs from threats such as data breaches, unauthorized access, and cyberattacks. Our team ensures that your APIs comply with relevant regulations.
                                </p>

                            </div>
                        </div>
                        {/* <!-- col --> */}
                        <div className="col-xl-6 col-lg-6 col-md-12 mb-2">
                            {/* <!-- box --> */}
                            <div className="box color-2">
                                {/* <!-- link --> */}
                                <a href="#" className="box-link"></a>
                                {/* <!-- icon --> */}
                                <div className="icon icon-style color3 m-auto">
                                    <FaRandom />
                                    {/* <img src="/assets/images/icons/priority.svg" className="lazy img-fluid" alt="priority" /> */}
                                </div>
                                {/* <!-- box-title --> */}
                                <h4 className="box-title">Documentation & Support</h4>
                                {/* <!-- box-para --> */}
                                <p className="box-para" style={{ display: "flex", height: 'auto', maxHeight: 'max-content' }}>
                                    Clear and comprehensive documentation is essential for the effective use and maintenance of APIs. We provide detailed API documentation that covers everything from setup instructions to usage guidelines, making it easy for your development team to work with the APIs. Additionally.
                                </p>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 mb-2">
                            {/* <!-- box --> */}
                            <div className="box color-2">
                                {/* <!-- link --> */}
                                <a href="#" className="box-link"></a>
                                {/* <!-- icon --> */}
                                <div className="icon icon-style color3 m-auto">
                                    <FaRandom />
                                    {/* <img src="/assets/images/icons/priority.svg" className="lazy img-fluid" alt="priority" /> */}
                                </div>
                                {/* <!-- box-title --> */}
                                <h4 className="box-title">Scalability & Performance Optimization</h4>
                                {/* <!-- box-para --> */}
                                <p className="box-para" style={{ display: "flex", height: 'auto', maxHeight: 'max-content' }}>
                                    To ensure your APIs can handle increased demand as your business grows, TechX provides Scalability & Performance Optimization services. We design APIs with scalability in mind, allowing them to support more users, data, and transactions as needed.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid mt-2 webhosting-all-section cloud-banner-section">
                <div className="row cloud-row-reverse">
                    <div className="col-lg-6 pt-1">
                        <div className="aboutus-text-section">
                            <h1 className="sec-title-2 mb-2">
                                Third-Party API Integration
                            </h1>
                            <p className="sec-para-1 mb-1">
                                Integrating third-party APIs can significantly enhance the functionality of your software systems. At TechX, we specialize in seamlessly integrating external APIs into your existing platforms, whether for payment processing, data analysis, social media integration, or other purposes. We ensure that the integration is smooth, secure, and aligned with your business objectives, allowing you to leverage the power of external services without compromising your internal operations.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 cls-img">

                        <div className="image-aboutus-section">
                            <img
                                src="assets/images/pages/APIDevelop/third.jpeg"
                                className=" img-fluid"
                                alt="Image"
                                style={{ borderRadius: 15 }}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="se-head mt-2 mb-5">
                <h3 className="se-title-1">FAQS</h3>
                <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                <Link to="/support"

                    className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                >
                    Support
                </Link >
            </div>
            {/* <FAQ /> */}
            <ThemeSetting />

            <Cookie />
        </>
    )
}

export default APIDevelop