import ThemeSetting from "./Include/ThemeSetting"
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const ITStrategy = ()=>{
    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);
    return(
        <>
                    <div className="container py-3">
                <div className="row">

                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/ITStrategy/itfirst.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center  ">
                        <h3 className="ml-lg-1 color-about F_40 text-lg-start text-center" data-aos="fade-up" >IT Strategy</h3>
                        <p className="ml-lg-1 textColor f_18 text-justify" data-aos="fade-down">
                        At TechX, we understand that a well-crafted IT strategy is essential for aligning technology with your business goals. Our IT Strategy services are designed to provide you with a clear, actionable roadmap that ensures your IT investments drive growth, innovation, and competitive advantage. We work closely with you to understand your unique needs and challenges, delivering a tailored strategy that sets the foundation for long-term success.
                        </p>
                    </div>
                </div>

            </div>
            <hr style={{ color: '#e2e9ee' }} />

            <div className="container py-3">
                <div className="row mb-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1 mr-lg-1" data-aos="fade-right" >Strategic Roadmapping</h3>
                        <p className="textColor f_18 text-justify mr-lg-1" data-aos="fade-down">
                        Our Strategic Roadmapping service helps you create a clear and actionable plan for your IT initiatives. We work with you to identify key objectives, assess your current technology landscape, and define the steps needed to achieve your business goals. By prioritizing initiatives and setting realistic timelines, we ensure that your IT strategy is aligned with your overall business strategy, providing a clear direction for the future.
                        </p>
                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/ITStrategy/business.jpg" className="w-100" style={{borderRadius:15}} />
                    </div>
                </div>
                <div className="row mb-2 d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/ITStrategy/business.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about f_25 mb-1" data-aos="fade-right" >Strategic Roadmapping</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-down" >
                        Our Strategic Roadmapping service helps you create a clear and actionable plan for your IT initiatives. We work with you to identify key objectives, assess your current technology landscape, and define the steps needed to achieve your business goals. By prioritizing initiatives and setting realistic timelines, we ensure that your IT strategy is aligned with your overall business strategy, providing a clear direction for the future.
                        </p>
                    </div>
                </div>


                <div className="row mb-2 pt-lg-2 pt-1">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/ITStrategy/tech.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center" >
                        <h3 className="color-about f_25 mb-1 ml-lg-1" data-aos="fade-left">Technology Assessment</h3>
                        <p className="textColor f_18 text-justify ml-lg-1" data-aos="fade-left">Through our comprehensive Technology Assessment, we evaluate your current systems to identify strengths, weaknesses, and areas for improvement. This detailed analysis allows us to recommend the right technologies that will enhance your operations and support your business objectives. Whether you're looking to modernize legacy systems or integrate new technologies, our assessment provides the insights needed to make informed decisions.

                        </p>
                    </div>
                </div>

                <div className="row mb-2 pt-2 d-lg-flex d-none">
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="mr-lg-1 color-about f_25 mb-1" data-aos="fade-left">Innovation Consulting</h3>
                        <p className="mr-lg-1 textColor f_18 text-justify" data-aos="fade-left">
                        Innovation is key to staying ahead in today’s fast-paced business environment. Our Innovation Consulting services explore emerging technologies and trends that can give your business a competitive edge. We help you identify opportunities for innovation, assess the potential impact on your operations, and develop strategies to implement these technologies effectively. By fostering a culture of innovation, we empower your business to adapt and thrive in a rapidly changing market.
                        </p>
                    </div>
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/ITStrategy/Innovation.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                </div>
                <div className="row mb-2 pt-1  d-lg-none d-block">
                    <div className="col-lg-6 p-lg-0 px-sm-4" data-aos="fade-right">
                        <img src="assets/images/pages/ITStrategy/Innovation.jpg" className="w-100" style={{borderRadius:15}} />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="color-about mb-1  f_25" data-aos="fade-left">Innovation Consulting</h3>
                        <p className="textColor f_18 text-justify" data-aos="fade-left">
                        Innovation is key to staying ahead in today’s fast-paced business environment. Our Innovation Consulting services explore emerging technologies and trends that can give your business a competitive edge. We help you identify opportunities for innovation, assess the potential impact on your operations, and develop strategies to implement these technologies effectively. By fostering a culture of innovation, we empower your business to adapt and thrive in a rapidly changing market.
                        </p>
                    </div>
                </div>

                <div className="row mb-2 ">
                    <div className="col-lg-6 p-lg-0 pt-lg-2 pt-1 px-sm-4" data-aos="fade-left">
                        <img src="assets/images/pages/ITStrategy/risk.jpg" className="w-100" style={{borderRadius:15}}/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h3 className="ml-lg-1 mb-1 color-about f_25" data-aos="fade-right">Risk Management</h3>
                        <p className="ml-lg-1 textColor f_18 text-justify" data-aos="fade-right">Effective Risk Management is crucial to ensuring the success of your IT initiatives. Our approach involves identifying potential risks early in the planning process and developing strategies to mitigate them. We work with you to assess the impact of these risks on your business and implement controls to minimize their effect. By proactively managing risks, we help you avoid costly disruptions and ensure that your IT projects are completed on time and within budget.</p>
                    </div>
                </div>
                <div className="se-head mt-sm-3 mt-3 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link  to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
            <ThemeSetting />
        </>
    )
}

export default ITStrategy