/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import "./styles/UiDesigning.css";
import { TiSupport } from "react-icons/ti";
import { GrUserExpert } from "react-icons/gr";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { GrTechnology } from "react-icons/gr";
import { FaBuromobelexperte } from "react-icons/fa";
import { MdOutlineIndeterminateCheckBox } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ThemeSetting from "./Include/ThemeSetting";
import { FaLaptopCode } from "react-icons/fa6";
import { FaMobileAlt } from "react-icons/fa";
import { MdSecurityUpdateGood } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";


import {
  SiMaterialdesign,
  SiAffinitydesigner,
  SiMaterialdesignicons,
} from "react-icons/si";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";


const MobileAppDevelopment = () => {
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  var history = useHistory();

  const baseUrl = location.href.includes("trainingncr.info")
    ? `http://trainingncr.info/techx/assets/images`
    : `/assets/images`;



  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    className: "myCustomCarousel",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  var settingss = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    className: "myCustomCarousel",
  };

  const handleFAQClick = (e) => {
    const questions = document.querySelectorAll(".q");

    questions.forEach((question) => {
      if (question !== e.currentTarget) {
        question.classList.remove("open");
        question.querySelector(".q-b").style.display = "none";
      }
    });
    e.currentTarget.classList.toggle("open");
    const qb = e.currentTarget.querySelector(".q-b");

    if (qb) {
      qb.style.display = qb.style.display === "block" ? "none" : "block";
    }
  };
    return (
        <>
        <div className="Ui-designing-section">
            <div className="container">
                <div className="description">
                    <h2 className="uides-desciption">Web & Mobile App Development</h2>
                    <h3 className="title-uidesign mt-1">
                    At TechX, we excel in crafting innovative web and mobile applications that drive your business forward
                    </h3>
                </div>
                <div className="row row-box">
                    <div className="col-lg-6 mb-3 p-2  col-md-6  box-content" style={{height:'auto'}}>
                        <div className="icon-section-uidesgn">
                            <a>
                                <FaLaptopCode className="icon-uides" />
                            </a>
                        </div>
                        <h3 className="title-uidesign mt-1">Web Application Development </h3>
                        <p className="card-details-uidesign text-justify">
                        Our Custom Web Application Development services focus on creating bespoke web solutions that cater to your specific business needs. From enterprise-grade applications to interactive platforms, we deliver tailored solutions that improve operational efficiency and user engagement. We begin with a deep dive into your requirements, designing a solution that integrates seamlessly with your existing systems and aligns with your strategic goals. With a focus on scalability and performance, our web applications are built to grow with your business and adapt to changing market demands.
                        </p>
                    </div>
                    <div className="col-lg-6 mb-3 p-2  col-md-6  box-content" style={{height:'auto'}}>
                        <div className="icon-section-uidesgn">
                            <a>
                                <FaMobileAlt className="icon-uides" />
                            </a>
                        </div>

                        <h3 className="title-uidesign mt-1">Mobile App Development </h3>
                        <p className="card-details-uidesign text-justify">
                        In the mobile-first world, having a robust and intuitive mobile app is crucial for business success. Our Mobile App Development services cover both iOS and Android platforms, ensuring that your app reaches your audience wherever they are. We leverage the latest technologies and development frameworks to build apps that offer a seamless user experience and exceptional performance. From initial concept and design to development and deployment, our team ensures that your mobile app stands out in the crowded marketplace and delivers lasting value.
                        </p>
                    </div>
                    <div className="col-lg-6 mb-3 p-2  col-md-6  box-content" style={{height:'auto'}}>
                        <div className="icon-section-uidesgn">
                            <a>
                                <MdSecurityUpdateGood className="icon-uides" />
                            </a>
                        </div>
                        <h3 className="title-uidesign mt-1">Quality Assurance & Testing </h3>
                        <p className="card-details-uidesign text-justify">
                        Quality Assurance (QA) & Testing are integral to our development process, ensuring that your applications are reliable, secure, and perform flawlessly. We conduct extensive testing throughout the development cycle, including functional, performance, and security tests, to identify and resolve any issues before launch. Our QA team uses a combination of automated and manual testing methods to deliver a product that meets the highest standards of quality and reliability.
                        </p>
                    </div>
                    <div className="col-lg-6 mb-3 p-2  col-md-6  box-content" style={{height:'auto'}}>
                        <div className="icon-section-uidesgn">
                            <a>
                                <FaHandshake className="icon-uides" />
                            </a>
                        </div>
                        <h3 className="title-uidesign mt-1">Support & Maintenance </h3>
                        <p className="card-details-uidesign text-justify">
                        Our commitment to your success continues long after your app is launched. Our Ongoing Support & Maintenance services ensure that your web and mobile applications remain up-to-date, secure, and fully operational. We provide regular updates, bug fixes, and enhancements to keep your applications running smoothly and address any issues that may arise. With TechX, you can rely on ongoing support to ensure the long-term success and performance of your digital solutions.
                        </p>
                    </div>
                </div>
            </div>
            <marquee className="allimage-text">
                We're shaping the perfect web solutions
            </marquee>


            <div className="container webhosting-all-section ">
                <div className="row ">
                    <div className="col-lg-6">
                        <div className="aboutus-text-section mt-1">
                            <h2 className="uides-desciption text-left">
                            User Experience (UX) Design
                            </h2>
                            <p className="Website-text-title-three mt-1">
                            User Experience (UX) Design is at the heart of our development process. We focus on creating intuitive and engaging interfaces that provide a smooth and enjoyable user journey. Our UX design approach involves thorough research and user testing to understand your audience’s needs and behaviors. We translate these insights into design solutions that enhance usability and satisfaction, ensuring that your web and mobile applications not only meet but exceed user expectations.
                            </p>
                            {/* <a
                    href=" https://xcreates.co/"
                    target="blank"
                    className="AI-text-title-one-btn"
                  >
                    Explore Now
                  </a> */}
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        <div className="p-2">
                            <img
                                src={baseUrl + "/icons/Uidesign-two.gif"}
                                className="lazy box-icon w-100"
                                alt="Mail"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="conatiner uidesign-slider-section">
                <Slider {...settings}>
                    <div>
                        <figure>
                            <div class="thumbnail">
                                <div className="">graphic designers working their desk</div>
                                <img
                                    // src="/assets/images/icons/UiDesign-three.jpg"
                                    src={baseUrl + "/icons/UiDesign-three.jpg"}
                                    className="lower-img-uidesign img-fluid"
                                    alt="priority"
                                />

                            </div>
                        </figure>
                    </div>
                    <div>
                        <figure>
                            <div class="thumbnail">
                                <div>handicapped business executive using digital tablet</div>
                                <img
                                    // src="/assets/images/icons/UiDesign-four.jpg"
                                    src={baseUrl + "/icons/UiDesign-four.jpg"}
                                    className="lower-img-uidesign img-fluid"
                                    alt="priority"
                                />
                            </div>
                        </figure>
                    </div>
                    <div>
                        <figure>
                            <div class="thumbnail">
                                <div>web template website design concept</div>
                                <img
                                    // src="/assets/images/icons/UiDesign-five.jpg"
                                    src={baseUrl + "/icons/UiDesign-five.jpg"}
                                    className="lower-img-uidesign img-fluid"
                                    alt="priority"
                                />
                            </div>
                        </figure>
                    </div>
                    <div>
                        <figure>
                            <div class="thumbnail">
                                <div>closeup hand using pen mouse working </div>
                                <img
                                    // src="/assets/images/icons/UiDesign-six.jpg"
                                    src={baseUrl + "/icons/UiDesign-six.jpg"}
                                    className="lower-img-uidesign img-fluid"
                                    alt="priority"
                                />
                            </div>
                        </figure>
                    </div>
                    <div>
                        <figure>
                            <div class="thumbnail">
                                <div>high angle man designing websites</div>
                                <img
                                    // src="/assets/images/icons/UiDesign-seven.jpg"
                                    src={baseUrl + "/icons/UiDesign-seven.jpg"}
                                    className="lower-img-uidesign img-fluid"
                                    alt="priority"
                                />

                            </div>
                        </figure>
                    </div>
                    <div>
                        <figure>
                            <div class="thumbnail">
                                <div>business people meeting office writing memos sticky notes</div>
                                <img
                                    // src="/assets/images/icons/UiDesign-nine.jpg"
                                    src={baseUrl + "/icons/UiDesign-nine.jpg"}
                                    className="lower-img-uidesign img-fluid"
                                    alt="priority"
                                />
                            </div>
                        </figure>
                    </div>
                </Slider>
            </div>
      
            <ThemeSetting />
        </div>
        <div className="container mt-sm-3">
                <div className="se-head mt-sm-3 mt-2 mb-5">
                    <h3 className="se-title-1">FAQS</h3>
                    <h4 className="se-title-2">Got questions?<br />Well, we've got answers.</h4>
                    <Link to="/support"
                        className="AI-text-title-one-btn" style={{ margin: 'auto' }}
                    >
                        Support
                    </Link>
                </div>

            </div>
        </>
    )
}

export default MobileAppDevelopment